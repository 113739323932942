import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const TopLastGraphic = ({ data1 }) => {
  const labelsTruncated = data1.map((item) => {
    let labelToShow = item.productName;
    if (labelToShow.length > 20) {
      labelToShow = labelToShow.substring(0, 17) + '...';
    }
    return labelToShow;
  });

  const fullLabels = data1.map(item => item.productName);

  const maxIncome = Math.max(...data1.map(item => parseFloat(item.income).toFixed(2)));
  const yAxisMax = maxIncome + 30;

  const data = {
    labels: labelsTruncated,
    datasets: [
      {
        data: data1.map(item => parseFloat(item.income).toFixed(2)),
        backgroundColor: 'rgba(34, 139, 34, 0.8)',
        borderColor: 'rgba(34, 139, 34, 1)',
        borderWidth: 1,
        yAxisID: 'y-axis-1',
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
      },
      'y-axis-1': {
        type: 'linear',
        position: 'left',
        ticks: {
          callback: function (value) {
            return value + '€';
          },
          max: yAxisMax,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            return fullLabels[index];
          },
        },
      },
      datalabels: {
        display: true,
        color: 'black',
        font: {
          weight: 'bold',
        },
        align: function (context) {
          // Muestra el texto dentro si la barra es muy alta, de lo contrario fuera
          const value = context.dataset.data[context.dataIndex];
          return value >= maxIncome * 0.9 ? 'center' : 'end';
        },
        anchor: function (context) {
          const value = context.dataset.data[context.dataIndex];
          return value >= maxIncome * 0.9 ? 'center' : 'end';
        },
        formatter: function (value, context) {
          const totalUnits = data1[context.dataIndex].totalUnits;
          return `${value}€\n${totalUnits} uds`;
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default TopLastGraphic;
