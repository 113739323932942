import React, { useState } from "react";
import "./FdRankingCrosselling.css";
import FdRankingCrossellingGraph from "../../../Components/Graphics/FdRankingCrossellingGraph";
import { useAppContext } from "../../../Shared/Context/AppContext";
import AllScreen from "../../../Components/AllScreen/AllScreen";

const rankingCrossellingTest = {
  reserve: [
    {
      concept: "Last check out",
      units: 10,
      income: 100,
    },
    {
      concept: "Early check in",
      units: 8,
      income: 50,
    },
    {
      concept: "Parking",
      units: 5,
      income: 74,
    },
    {
      concept: "Caja fuerte",
      units: 60,
      income: 60,
    },
    {
      concept: "más",
      units: 10,
      income: 85,
    },
  ],
  pax: [
    {
      concept: "MP",
      units: 14,
      income: 300,
    },
    {
      concept: "PC",
      units: 10,
      income: 400,
    },
    {
      concept: "TI",
      units: 5,
      income: 450,
    },
    {
      concept: "Picnic",
      units: 20,
      income: 200,
    },
    {
      concept: "Balinesa",
      units: 10,
      income: 100,
    },
    {
      concept: "Desayuno buffet",
      units: 3,
      income: 15,
    },
  ],
};

const FdRankingCrosselling = () => {
  const [graphType, setGraphType] = useState('all');
  const [itemSelected, setItemSelected] = useState('all');
  const [allScreen, setAllScreen]= useState(false);
  const {rol} = useAppContext();

  return (
    <div className={rol !== 'ADMIN' && allScreen ? "dataZone allScreen" : 
      rol !== 'ADMIN' && !allScreen ? 'dataZone' :
       rol === "ADMIN" && allScreen ? 'dataZoneAdmin allScreen' : 'dataZoneAdmin'}>
    <AllScreen setAllScreen={setAllScreen} allScreen={allScreen}/>
      <div className="fdRankingEmpleadosContainer">
        <div className="fdRankingEmpleadosFilter">
          <button
            className={
              itemSelected === "all"
                ? "fdRankingEmpleadosSelector fdRankingEmpleadosSelect"
                : "fdRankingEmpleadosSelector"
            }
            onClick={() => {
              setItemSelected("all");
              setGraphType("all");
            }}
          >
            Todo
          </button>
          <button
            className={
              itemSelected === "reserve"
                ? "fdRankingEmpleadosSelector fdRankingEmpleadosSelect"
                : "fdRankingEmpleadosSelector"
            }
            onClick={() => {
              setItemSelected("reserve");
              setGraphType("reserve");
            }}
          >
            Reserva
          </button>
          <button
            className={
              itemSelected === "pax"
                ? "fdRankingEmpleadosSelector fdRankingEmpleadosSelect"
                : "fdRankingEmpleadosSelector"
            }
            onClick={() => {
              setItemSelected("pax");
              setGraphType("pax");
            }}
          >
            Persona
          </button>
        </div>
        <div className="fdRankingEmpleadosGraphZone">
          {rankingCrossellingTest && graphType && (
            <FdRankingCrossellingGraph
              dataSet={rankingCrossellingTest}
              type={graphType}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FdRankingCrosselling;
