import api from "./api";

const dataGet = {
    getCurrency: async() => {
        return api.get('/currency/').then((response)=>{
            return response
        })
    },
    getLogo: async(id) => {
        return api.logoGet('/hotel_logo/' + id).then((response)=>{
            return response
        })
    },
    // getParticipationGraphic: async(id) => {
    //     return api.logoGet('/participation/' + id).then((response)=>{
    //         return response
    //     })
    // },
    dailyInfoRequired: async(id, dates) => {
        return api.get("/daily_info_required/hotel/" + id + '/' + dates).then((response) => {
            return response
        })
    },
    hotelGroup: async() => {
        return  api.get("/hotel_group/").then((response)=>{
            return response
        })
    },
    groupContacts: async(id) => {
        return  api.get("/group_contacts/group/" + id).then((response)=>{
            return response
        })
    },
    groupContactsAll: async(id) => {
        return  api.get("/group_contacts/all").then((response)=>{
            return response
        })
    },
    groupId: async(id) => {
        return api.get('/hotel_group/id/' + id).then((response)=> {
            return response
        })
    },
    hotelGroupId: async(id) => {
        return api.get('/hotel/by_group/' + id).then((response)=>{
            return response
        })
    },
    hotel: async() => {
        return api.get('/hotel/').then((response)=>{
            return response
        })
    },
    hotelId: async(id) => {
        return api.get('/hotel/id/' + id).then((response) => {
            return response
        })
    },
    hotelConsultant: async(id) => {
        return api.get('/hotel/by_consultant/' + id).then((response) => {
            return response
        })
    },
    hotelContacts: async(id) => {
        return api.get('/hotel_contacts/' + id ).then((response) => {
            return response
        })
    },
    hotelContactsAll: async(id) => {
        return api.get('/hotel_contacts/all' ).then((response) => {
            return response
        })
    },
    hotelStaff: async(id) => {
        return api.get('/staff/by_hotel/' + id).then((response)=>{
            return response
        })
    },
    hotelObjectives: async(id) => {
        return api.get('/hotel_objectives/hotel/' + id).then((response)=> {
            return response
        })
    },
    hotelRooms: async(id) => {
        return api.get('/rooms/id_hotel/' + id).then((response) => {
            return response
        })
    },
    hotelUpsellings: async(id) => {
        return api.get('/hotel_rooms_upselling/hotel/' + id).then((response)=>{
            return response
        })
    },
    hotelCrossellings: async(id) => {
        return api.get('/crosselling_items/hotel/' + id).then((response)=>{
            return response
        })
    },
    hotelHistory: async(id) => {
        return api.get('/historical/hotel/' + id).then((response)=>{
            return response
        })
    },
    regions: async() => {
        return api.get("/regions/").then((response) => {
            return response
        })
    },
    languages: async() => {
        return api.get('/languages/').then((response)=>{
            return response
        })
    },
    categories: async()=> {
        return api.get('/hotel_category/').then((response)=>{
            return response
        })
    },
    hotelTypes: async() => {
        return api.get('/hotel_type/').then((response) =>{
            return response
        })
    },
    roomTypes: async() => {
        return api.get('/room_type/').then((response)=>{
            return response
        })
    },
    personalTypes: async() => {
        return api.get('/personal_type/').then((response)=> {
            return response
        })
    },
    departments: async() => {
        return api.get('/departments/').then((response)=>{
            return response
        })
    },
    consultants: async() => {
        return api.get('/consultants/').then((response)=>{
            return response
        })
    },
    crossellingConcepts: async() => {
        return api.get('/crosselling_concepts/').then((response) => {
            return response
        })
    },
    dailyRegister: async(id) => {
        return api.get('/daily_entry/hotel/' + id).then((response) => {
            return response
        })
    },
    fbRegister: async(id, filterDates) => {
        return api.get('/f_b/hotel/' + id + '/' + filterDates).then((response) => {
            return response
        })
    },
    fbItems: async(id) => {
        return api.get('/fb_items/hotel/' + id).then((response) => {
            return response
        })
    },
    sellPoints: async() => {
        return api.get('/sell_points').then((response)=>{
            return response
        })
    },
    sellPointsByHotel: async(id) => {
        return api.get('/sell_points/hotel/' + id).then((response)=>{
            return response
        })
    },
    getFBData: async(dates, idHotel, pdf, cadena, periodicity) => {
        const endpoint = `/front/${idHotel}/${dates}/${pdf}/${cadena}/${periodicity}`;
        return api.get(endpoint)
    },
    getFBdailyReport: async(dates, idHotel, pdf, cadena) => {
        const endpoint = `/front/reports/day/${idHotel}/${dates}/${pdf}/${cadena}`;
        return api.get(endpoint)
    },
    getFBweekReport: async(dates, idHotel, pdf, cadena) => {
        const endpoint = `/front/reports/week/${idHotel}/${dates}/${pdf}/${cadena}`;
        return api.get(endpoint)
    },
    getFBmonthReport: async(dates, idHotel, pdf, cadena) => {
        const endpoint = `/front/reports/month/${idHotel}/${dates}/${pdf}/${cadena}`;
        return api.get(endpoint)
        },
    getFDData: async(dates, idHotel, pdf, cadena) => {
        const endpoint = `/fd_data/${idHotel}/${dates}/${pdf}/${cadena}`;
        return api.get(endpoint)
    },
    getCalendarDates: async(idHotel) => {
        const endpoint = `/front/dates/${idHotel}`;
        return api.get(endpoint)
    }
   
};

export default dataGet;