import React, { useEffect, useState } from "react";
import "./RankingSellPoints.css";
import { useAppContext } from "../../../Shared/Context/AppContext";
import dataGet from "../../../Shared/API/dataGet";
import useLanguages from "../../../Shared/languages/setLanguages";
import AllScreen from "../../../Components/AllScreen/AllScreen";

const formatNumber = (number) => {
  const numberStr = Number(number).toFixed(2);
  const [numberWithoutDec, decNumber] = numberStr.split(".");
  const formattedInt = numberWithoutDec.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (decNumber !== "00") {
    return `${formattedInt},${decNumber}`;
  } else {
    return `${formattedInt}`;
  }
};

const RankingSellPoint = () => {
  const { rol, datosDashboardNew } = useAppContext();

  const [allScreen, setAllScreen] = useState(false)
  const changeWord = useLanguages();

  return (
    <div className={rol !== 'ADMIN' && allScreen ? "dataZone allScreen" : 
      rol !== 'ADMIN' && !allScreen ? 'dataZone' :
       rol === "ADMIN" && allScreen ? 'dataZoneAdmin allScreen' : 'dataZoneAdmin'}>
    {/* <AllScreen setAllScreen={setAllScreen} allScreen={allScreen}/> */}
      <div className="rankingProductsContainer">
        <div className="productsList">
          <p className="productsTitle">{changeWord('rankingDePuntosDeVenta')}</p>
          <div className="rankingItem">
            <p className="colProduct colTitle">{changeWord('puntosDeVenta')}</p>
            <p className="colIncome colTitle">{changeWord('ingresos')}</p>
            <p className="colUnits colTitle">{changeWord('unidades')}</p>
          </div>
          {datosDashboardNew && datosDashboardNew.pointOfSaleIncome.length > 0 &&
            datosDashboardNew && datosDashboardNew.pointOfSaleIncome.map((item, index) => (
              <div key={index} className="rankingItem">
                <p className="colProduct">{item.pointOfSaleName}</p>
                <p className="colUnits">{formatNumber(item.pointOfSaleIncome)}€</p>
                <p className="colUnits">{datosDashboardNew.pointOfSaleUnits.find((itemUnits) => itemUnits.pointOfSaleName === item.pointOfSaleName).pointOfSaleUnits}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default RankingSellPoint;
