import React, { useState } from "react";
import "./FdRankingEmpleados.css";
import FdRankingEmpleadosGraph from "../../../Components/Graphics/FdRankingEmpleadosGraph";
import { useAppContext } from "../../../Shared/Context/AppContext";
import AllScreen from "../../../Components/AllScreen/AllScreen";

const rankinEmpleadosTest = [
  {
    name: "empleado1",
    upselling: {
      income: 50,
      units: 5,
    },
    crosselling: {
      income: 20,
      units: 3,
    },
  },
  {
    name: "empleado2",
    upselling: {
      income: 55,
      units: 4,
    },
    crosselling: {
      income: 25,
      units: 4,
    },
  },
  {
    name: "empleado3",
    upselling: {
      income: 40,
      units: 6,
    },
    crosselling: {
      income: 15,
      units: 10,
    },
  },
];

const FdRankingEmpleados = () => {
  const [graphType, setGraphType] = useState('all');
  const [itemSelected, setItemSelected] = useState('all');
  const [allScreen, setAllScreen] = useState(false);
  const { rol } = useAppContext();

  return (
    <div className={rol !== 'ADMIN' && allScreen ? "dataZone allScreen" : 
      rol !== 'ADMIN' && !allScreen ? 'dataZone' :
       rol === "ADMIN" && allScreen ? 'dataZoneAdmin allScreen' : 'dataZoneAdmin'}>
    <AllScreen setAllScreen={setAllScreen} allScreen={allScreen}/>
      <div className="fdRankingEmpleadosContainer">
        <div className="fdRankingEmpleadosFilter">
          <button
            className={
              itemSelected === "all"
                ? "fdRankingEmpleadosSelector fdRankingEmpleadosSelect"
                : "fdRankingEmpleadosSelector"
            }
            onClick={() => {
              setItemSelected("all");
              setGraphType("all");
            }}
          >
            Todo
          </button>
          <button
            className={
              itemSelected === "upselling"
                ? "fdRankingEmpleadosSelector fdRankingEmpleadosSelect"
                : "fdRankingEmpleadosSelector"
            }
            onClick={() => {
              setItemSelected("upselling");
              setGraphType("upselling");
            }}
          >
            Upselling
          </button>
          <button
            className={
              itemSelected === "crosselling"
                ? "fdRankingEmpleadosSelector fdRankingEmpleadosSelect"
                : "fdRankingEmpleadosSelector"
            }
            onClick={() => {
              setItemSelected("crosselling");
              setGraphType("crosselling");
            }}
          >
            Crosselling
          </button>
        </div>
        <div className="fdRankingEmpleadosGraphZone">
          <FdRankingEmpleadosGraph
            dataSet={rankinEmpleadosTest}
            type={graphType}
          />
        </div>
      </div>
    </div>
  );
};

export default FdRankingEmpleados;
