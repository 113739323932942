import React from "react";
import "./NoService.css";

const NoService = ({ type }) => {
  return (
    <div className="dataZone">
      <div className="noServiceContainer">
        <p>Este proyecto no tiene establecido un plan de </p>
        <p>{type}</p>
        <p>en este momento</p>
      </div>
    </div>
  );
};

export default NoService;
