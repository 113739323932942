import { useAppContext } from '../Context/AppContext';
import frontLanguages from './Languages'; // Importa el objeto

// Cambiamos el nombre de la función para que empiece con 'use'
const useLanguages = () => {
    const { language } = useAppContext();  // Obtén el idioma del contexto

    // Retorna la función changeWord
    const changeWord = (item) => {
        if (frontLanguages[item]) {
            return frontLanguages[item][language]; // Retorna la palabra en el idioma correcto
        } else {
            return ''; // Si no existe, retorna un string vacío
        }
    };

    return changeWord;  // Retorna la función que hará la traducción
};

export default useLanguages;

