import React, { useEffect, useState } from "react";
import "./RankingProductos.css";
import { useAppContext } from "../../../Shared/Context/AppContext";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import * as XLSX from "xlsx";
import useLanguages from "../../../Shared/languages/setLanguages";
import AllScreen from "../../../Components/AllScreen/AllScreen";
import Products from "../FB/ProductsView";

const getTextSize = (text, fontSize, font) => {
  return font.widthOfTextAtSize(text, fontSize);
};
const formatNumber = (number) => {
  const numberStr = Number(number).toFixed(2);
  const [numberWithoutDec, decNumber] = numberStr.split(".");
  const formattedInt = numberWithoutDec.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (decNumber !== "00") {
    return `${formattedInt},${decNumber}`;
  } else {
    return `${formattedInt}`;
  }
};
const RankingProductos = () => {
  const { rol, datosDashboardNew, setDataZoneView } = useAppContext();
  const [dataToShow, setDataToShow] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [hotelName, setHotelName] = useState(null);
  const [totals, setTotals] = useState(null);
  const [allScreen, setAllScreen] = useState(false);
  const [renderShow, setRenderShow] = useState(0);

  const changeWord = useLanguages()
  const generateExcel = () => {
    const worksheetData = [
      ["Producto", "Ingreso", "Unidades"], // Encabezados
    ];

    // Añadir los datos del listado
    dataToShow.forEach((item) => {
      worksheetData.push([
        item.productName,
        `${item.totalPrice} €`,
        item.totalUnits,
      ]);
    });

    // Crear el worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    worksheet["!cols"] = [
      { wpx: 300 }, // Ancho para la columna ID
      { wpx: 100 }, // Ancho para la columna Descripción
      { wpx: 100 }, // Ancho para la columna Precio
    ];
    // Crear el workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Productos");

    // Generar el archivo Excel
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Descargar el archivo Excel
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "products_list.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const generatePDF = async () => {
    const pdfDoc = await PDFDocument.create();
    let page = pdfDoc.addPage([600, 800]);
    const customFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const fontSize = 10;
    let yPosition = 750; // Ajustar la posición inicial

    // Añadir un título
    const titleLength = getTextSize(
      `Ranking de productos ${hotelName}`,
      20,
      customFont
    );
    page.drawText(`Ranking de productos ${hotelName}`, {
      x: (600 - titleLength) / 2,
      y: yPosition,
      size: 20,
      color: rgb(0, 0, 0),
    });

    yPosition -= 30; // Espacio después del título

    // Añadir encabezados de tabla

    page.drawText("Producto", {
      x: 120,
      y: yPosition,
      size: fontSize,
      color: rgb(0, 0, 0),
    });
    page.drawText("Ingreso", {
      x: 390,
      y: yPosition,
      size: fontSize,
      color: rgb(0, 0, 0),
    });
    page.drawText("Unidades", {
      x: 450,
      y: yPosition,
      size: fontSize,
      color: rgb(0, 0, 0),
    });

    yPosition -= 20;

    // Añadir los datos del listado
    dataToShow.forEach((item) => {
      if (yPosition < 50) {
        // Añadir una nueva página si se alcanza el final de la página actual
        page = pdfDoc.addPage([600, 800]); // Cambia la referencia de `page` a la nueva página
        yPosition = 750; // Reinicia la posición Y en la nueva página

        // Añadir los encabezados de la tabla en la nueva página
        console.log(item)
        page.drawText("Producto", {
          x: 120,
          y: yPosition,
          size: fontSize,
          color: rgb(0, 0, 0),
        });
        page.drawText("Ingreso", {
          x: 390,
          y: yPosition,
          size: fontSize,
          color: rgb(0, 0, 0),
        });
        page.drawText("Unidades", {
          x: 450,
          y: yPosition,
          size: fontSize,
          color: rgb(0, 0, 0),
        });
        yPosition -= 20;
      }

      // Añadir los datos del producto

      page.drawText(item.productName, {
        x: 120,
        y: yPosition,
        size: fontSize,
        color: rgb(0, 0, 0),
      });

      const priceSize = getTextSize(
        `${item.income.toFixed(2)} €`,
        fontSize,
        customFont
      );
      page.drawText(`${item.income.toFixed(2)} €`, {
        x: 425 - priceSize,
        y: yPosition,
        size: fontSize,
        color: rgb(0, 0, 0),
      });
      page.drawText(`${item.totalUnits}`, {
        x: 460,
        y: yPosition,
        size: fontSize,
        color: rgb(0, 0, 0),
      });
      yPosition -= 15;
    });

    page.drawText('Total', {
      x: 120,
      y: yPosition - 10,
      size: fontSize + 3,
      color: rgb(0, 0, 0),
    });
    const totalesSize = getTextSize(
      `${totals.income.toFixed(2)} €`,
      fontSize,
      customFont
    );
    page.drawText(`${totals.income.toFixed(2)} €`, {
      x: 425 - totalesSize,
      y: yPosition - 10,
      size: fontSize + 3,
      color: rgb(0, 0, 0),
    });
    page.drawText(`${totals.units}`, {
      x: 460,
      y: yPosition - 10,
      size: fontSize + 3,
      color: rgb(0, 0, 0),
    });

    const pdfBytes = await pdfDoc.save();

    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "products_list.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  useEffect(() => {
    if (datosDashboardNew && datosDashboardNew.topLastProductsHotel.all) {
      const actualData = datosDashboardNew.topLastProductsHotel.all
      setDataToShow(actualData)
      const ingresosTotales = actualData.reduce((acc, item) => {
        return acc + item.income
      }, 0)
      const unidadesTotales = actualData.reduce((acc, item) => {
        return acc + item.totalUnits
      }, 0)
      setTotals({ units: unidadesTotales, income: ingresosTotales })
      setHotelName(datosDashboardNew.hotelName)
    }
  }, [datosDashboardNew])

  const fnDownloadFile = () => {
    const fileTypeInt = parseInt(fileType);
    if (fileTypeInt !== 0) {
      if (fileTypeInt === 1) {
        generatePDF();
      } else if (fileTypeInt === 2) {
        generateExcel();
      }
    }
  };
  return (
    <div className={rol !== 'ADMIN' && allScreen ? "dataZone allScreen" :
      rol !== 'ADMIN' && !allScreen ? 'dataZone' :
        rol === "ADMIN" && allScreen ? 'dataZoneAdmin allScreen' : 'dataZoneAdmin'}>
      {/* <AllScreen setAllScreen={setAllScreen} allScreen={allScreen} /> */}
      <div className="rankingProductsContainer">
        <div className="productsSelector">
          <p
          onClick={() => setRenderShow(0)}
          className={renderShow === 0 && 'productSelected'}
          >vendidos</p>
          <p 
          onClick={() => setRenderShow(1)}
          className={renderShow === 1 && 'productSelected'}
          >todos</p>
        </div>

        {renderShow === 0 ?
          <div>
            <div className="downloadOptionsDownload">
              <select onChange={(e) => setFileType(e.target.value)}>
                <option value={0}>{changeWord('formato')}</option>
                <option value={1}>PDF</option>
                <option value={2}>EXCEL</option>
              </select>
              <button onClick={() => fnDownloadFile()}>{changeWord('descargar')}</button>
            </div>
            <div className="productsList">
              <p className="productsTitle">{changeWord('productosVendidos')}</p>
              <div className="rankingItem">
                <p className="colProduct colTitle">{changeWord('productos')}</p>
                <p className="colIncome colTitle">{changeWord('ingresos')}</p>
                <p className="colUnits colTitle">{changeWord('unidades')}</p>
              </div>
              <div className="rankingItem" id="rankingTotales">
                <p className="colProduct">{changeWord('totales')}</p>
                {totals && totals.income && <p className="colIncome">{formatNumber(totals.income)}€</p>}
                {totals && totals.units && <p className="colUnits" >{formatNumber(totals.units)}</p>}
              </div>
              {dataToShow &&
                dataToShow.length > 0 &&
                dataToShow.map((item, index) => (
                  <div key={index} className="rankingItem">
                    <p className="colProduct">{item.productName}</p>
                    <p className="colIncome">{formatNumber(item.income)}€</p>
                    <p className="colUnits">{formatNumber(item.totalUnits)}</p>
                  </div>
                ))}
            </div>

          </div> : <Products />} 




      </div>
    </div>
  );
};

export default RankingProductos;
