import React, { useEffect, useState } from "react";
import "./ObjectivesView.css";
import { useAppContext } from "../../../Shared/Context/AppContext";
import dataGet from "../../../Shared/API/dataGet";
import NoPossibleAllHotlels from './NoPossibleAllHotels'
import dataPut from "../../../Shared/API/dataPut";
import NewObjective from "./NewObjective";
import dataPost from "../../../Shared/API/dataPost";
import DeleteObjective from "./DeleteObjective";
import dataDelete from "../../../Shared/API/dataDelete";

const formatNumber = (number) => {
  const numberStr = Number(number).toFixed(2);
  const [numberWithoutDec, decNumber] = numberStr.split(".");
  const formattedInt = numberWithoutDec.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (decNumber !== "00") {
    return `${formattedInt},${decNumber}`;
  } else {
    return `${formattedInt}`;
  }
};
const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
  "Anual",
];

const ObjectivesView = () => {
  const { rol, datosDashboardNew } = useAppContext();
  const [objectivesData, setObjectivesData] = useState(null);
  const [objectivesDataFiltered, setObjectivesDataFiltered] = useState(null);

  const [yearList, setYearList] = useState([]);
  const [yearSelected, setYearSelected] = useState(null);
  const [monthSelected, setMonthSelected] = useState(null);

  const [objectiveEdit, setObjectiveEdit] = useState(null);
  const [deleteObjective, setDeleteObjective] = useState(null);

  const [newObjectiveVisible, setNewObjectiveVisible] = useState(false);
  const [deleteObjectiveVisible, setDeleteObjectiveVisible] = useState(false);

  const [allScreen, setAllScreen] = useState(false)

  const fnGetObjectives = () => {
    dataGet.hotelObjectives(datosDashboardNew.hotelId)
      .then((objectivesResponse) => {
        const result = objectivesResponse.map((item) => {
          const year = item.year;
          const objectiveId = item._id;
          const month = months[parseInt(item.month) - 1]; // Asegúrate de que `months` sea un array de nombres de meses.
          const monthObjective = item.totalObjective;
          const sellPointsObjective = item.fb.map((ThisSellPoint) => {
            const sellPoint = ThisSellPoint.idSellPoint.sellPointName;
            const sellPointId = ThisSellPoint.idSellPoint._id;
            const sellPointObjective = ThisSellPoint.percent;
            return { sellPoint: sellPoint, objective: sellPointObjective, sellPointId: sellPointId };
          });
          return {
            objectiveId: objectiveId,
            year: year,
            month: month,
            monthObjective: monthObjective,
            sellPointObjectives: sellPointsObjective,
          };
        });

        result.sort((a, b) => {
          const yearComparation = b.year.localeCompare(a.year);
          if (yearComparation === 0) {
            return months.indexOf(b.month) - months.indexOf(a.month);
          } else {
            return yearComparation;
          }
        });
        setObjectivesData(result);
        setObjectivesDataFiltered(result);

        const myYearList = result.map((item) => item.year);
        setYearList([...new Set(myYearList)]);

        const actualYear = new Date()
      });
  }
  useEffect(() => {
    if (datosDashboardNew.hotelId) {
      fnGetObjectives()
    }

  }, [datosDashboardNew]);



  useEffect(() => {
    if (
      yearSelected &&
      monthSelected &&
      yearSelected !== "50" &&
      monthSelected !== "12"
    ) {
      filterObjectives(monthSelected, yearSelected);
    } else if (yearSelected === "50") {
      setObjectivesDataFiltered(objectivesData);
    } else if (objectivesData && monthSelected === "12") {
      const actualObjectivesData = [...objectivesData];

      const actualObjectivesGrouped = objectivesData.reduce((acc, objective) => {
        return acc + objective.monthObjective;
      }, 0);

      const sellPointsObjectivesArr = objectivesData
        .flatMap((item) => item.sellPointObjectives)
        .reduce((acc, { sellPoint, objective }) => {
          const existingSellPoint = acc.find((item) => item.sellPoint === sellPoint);

          if (existingSellPoint) {
            existingSellPoint.objective += objective;
          } else {
            acc.push({ sellPoint, objective });
          }

          return acc;
        }, []);

      const objectToPush = {
        year: yearSelected,
        month: "Acumulado anual",
        monthObjective: actualObjectivesGrouped,
        sellPointObjectives: sellPointsObjectivesArr,
      };

      actualObjectivesData.unshift(objectToPush);
      setObjectivesDataFiltered(actualObjectivesData);
    }
  }, [yearSelected, monthSelected]);

  const filterObjectives = (month, year) => {
    const myMonth = months[parseInt(month)];
    setObjectivesDataFiltered(objectivesData);
    const dataFiltered = objectivesData.filter((item) => item.year.toString() === year && item.month === myMonth);
    setObjectivesDataFiltered(dataFiltered)

  };

  const fnUpdateObjective = (objectToUpdate) => {
    const myId = objectToUpdate.objectiveId;
    const myMonthObjective = objectToUpdate.monthObjective;
    const sellPointSum = objectToUpdate.sellPointObjectives.reduce((acc, item) => {
      return acc + parseInt(item.objective)
    }, 0);

    const percentObjectives = objectToUpdate.sellPointObjectives.map((sellPoint) => {
      return {
        idSellPoint: sellPoint.sellPointId,
        percent: parseInt(sellPoint.objective),
      };
    });

    const objectToUpdateFb = {
      totalObjective: sellPointSum,
      fb: percentObjectives,
    };

    dataPut.objectives(myId, objectToUpdateFb).then(() => {
      fnGetObjectives()
    })
  };

  const fnDeleteObjective = async(objectiveToDelete) => {
    const myId = objectiveToDelete;
    await dataDelete.deleteObjective(myId).then(() => fnGetObjectives());
    
  }

  const fnCreateObjective = async (data) => {
    const hotelSellPoints = await dataGet.sellPointsByHotel(datosDashboardNew.hotelId)
    const allSellPoints = []
    for (const month of objectivesDataFiltered) {
      const sellPoints = month.sellPointObjectives.map((sellPoint) => {
        return {
          sellPoint: sellPoint.sellPoint,
          sellPointId: sellPoint.sellPointId
        }
      });
      for (const sellPoint of sellPoints) {
        if (!allSellPoints.some((item) => item.sellPointId === sellPoint.sellPointId)) {
          allSellPoints.push(sellPoint)
        }
      }
    }
    const realMonth = parseInt(data.month) + 1;
    const newObjective = {
      idHotel: datosDashboardNew.hotelId,
      month: realMonth.toString(),
      year: data.year.toString(),
      totalObjective: 0,
      fb: hotelSellPoints.map((item) => {
        return (
          {
            idSellPoint: item._id,
            percent: 0
          }
        )
      })
    };
    await dataPost.hotelObjective(newObjective);
    fnGetObjectives();
  };

  return (
    <div className={rol !== 'ADMIN' && allScreen ? "dataZone allScreen" :
      rol !== 'ADMIN' && !allScreen ? 'dataZone' :
        rol === "ADMIN" && allScreen ? 'dataZoneAdmin allScreen' : 'dataZoneAdmin'}>
      {/* <AllScreen setAllScreen={setAllScreen} allScreen={allScreen} /> */}
      {datosDashboardNew.hotelId ?
        <div className="objectivesContainer">
          <div className="filterBox">
            <p className="filterBoxTitle">Filtrar objetivos</p>
            <div className="filterBoxSelectsBox">
              <div className="filterBoxSelectsContainer">
                <div className="filterBoxSelects">
                  <select
                    onChange={(e) => setYearSelected(e.target.value)}
                    className="filterBoxSelectItem"
                  >
                    <option value={50}>Año</option>
                    {yearList &&
                      yearList.length > 0 &&
                      yearList.map((item, index) => (
                        <option key={index}>{item}</option>
                      ))}
                  </select>
                  <select
                    onChange={(e) => setMonthSelected(e.target.value)}
                    className="filterBoxSelectItem"
                  >
                    <option value={50}>Mes</option>
                    {months &&
                      months.length > 0 &&
                      months.map((item, index) => (
                        <option key={index} value={index}>
                          {item}
                        </option>
                      ))}
                  </select>


                </div>
                <div>
                  <button
                    className="editObjectives"
                    onClick={() => {
                      setNewObjectiveVisible(true)
                    }
                    }>Crear objetivo</button>
                </div>

                {newObjectiveVisible &&
                  <NewObjective
                    setNewObjectiveVisible={setNewObjectiveVisible}
                    months={months}
                    onSubmit={(newObjectiveData) => fnCreateObjective(newObjectiveData)}
                    objectivesData={objectivesData}
                  />}

                {deleteObjectiveVisible &&
                <DeleteObjective
                  setDeleteObjectiveVisible = {setDeleteObjectiveVisible}
                  onSubmit={(e) => fnDeleteObjective(e)}
                  deleteObjective = {deleteObjective}
              />}
              </div>

            </div>
          </div>

          {objectivesDataFiltered &&
            objectivesDataFiltered.length > 0 &&
            objectivesDataFiltered.map((objective, index) => (
              <div key={index}
                className={objective.month !== 'Acumulado anual' ? "objectivesTitle" : "objectivesTitle objectivesItemReduce"}
              >
                <div className="objectivesTime">
                  <p>{objective.year}</p>
                  <p>{objective.month}</p>

                  <p className="detailValue">{formatNumber(objective.monthObjective)}€</p>

                  {(!objectiveEdit || parseInt(objectiveEdit) === index) && objective.month !== 'Acumulado anual' &&
                    <button
                      className="editObjectives"
                      onClick={() => {
                        if (!objectiveEdit) {
                          setObjectiveEdit(index.toString())
                        } else {
                          fnUpdateObjective(objectivesDataFiltered[index])
                          setObjectiveEdit(null)
                        }
                      }}>{!objectiveEdit || parseInt(objectiveEdit) !== index ? 'editar' : 'actualizar'}
                    </button>
                  }
                  <button
                    className="editObjectives"
                    onClick={() => {
                      setDeleteObjectiveVisible(true)
                      setDeleteObjective(objectivesDataFiltered[index])
                    }}>eliminar
                  </button>

                </div>
                <div className="objectivesGroup">
                  {objective.sellPointObjectives &&
                    objective.sellPointObjectives.map((itemFb, indexFb) => (
                      <div key={indexFb} className="objectiveDetail">
                        <p className="detailText">
                          {itemFb.sellPoint}
                        </p>


                        {parseInt(objectiveEdit) !== index ?
                          <p className="detailValue">{formatNumber(itemFb.objective)}€</p>
                          : <input className="detailValue detailValueEdit"
                            type="number"
                            step="1"
                            onKeyDown={(e) => {
                              if (e.key === '.' || e.key === ',') {
                                e.preventDefault();
                              }
                            }}
                            defaultValue={itemFb.objective}
                            onChange={(e) => {
                              const newObjectivesData = [...objectivesDataFiltered];
                              newObjectivesData[index].sellPointObjectives[indexFb] = {
                                ...newObjectivesData[index].sellPointObjectives[indexFb],
                                objective: parseInt(e.target.value)
                              };
                              setObjectivesDataFiltered(newObjectivesData);
                            }}
                          />}


                      </div>
                    ))}
                </div>
              </div>
            ))}
        </div>

        : <NoPossibleAllHotlels />
      }
    </div>
  );
};

export default ObjectivesView;
