const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const thisUserName = localStorage.getItem('userConnectedName')
const thisUserPosition = localStorage.getItem('userConnectedPosition')

const api = {
  get: async (endpoint) => {
    try {
      const response = await fetch(BASE_URL + endpoint, {
        headers: {
          key: API_KEY,
          user: thisUserName,
          position: thisUserPosition,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("error GET", error);
      throw error;
    }
  },

  logoGet: async (endpoint) => {
    const response = await fetch(BASE_URL + endpoint, {
      headers: {
        key: API_KEY,
        user: thisUserName,
        position: thisUserPosition,
      },
    });
    if (!response.ok) {
      throw new Error('Error al obtener la imagen');
    }
    const blob = await response.blob();
    const imageUrl = URL.createObjectURL(blob);
    return imageUrl;
  },

  post: async (endpoint, body) => {
    try {
      const response = await fetch(BASE_URL + endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          key: API_KEY,
          user: thisUserName,
          position: thisUserPosition,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error en la petición POST:", error);
      throw error;
    }
  },

  getReport: async (endpoint, data) => {
    try {
      const response = await fetch(BASE_URL + endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          key: API_KEY,
          user: thisUserName,
          position: thisUserPosition,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Error en la petición POST: " + response.statusText);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      if (data.download) {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", data.fileName + ".pdf");
        document.body.appendChild(link);
        link.click();
      } else {
        window.open(url, '_blank');
      }
    } catch (error) {
      console.error("Error en la petición POST:", error);
    }
  },

  postFormData: async (endpoint, formData) => {
    try {
      const response = await fetch(BASE_URL + endpoint, {
        method: "POST",
        body: formData,
        // No es necesario establecer Content-Type, el navegador lo hace automáticamente
      });
      const data = await response.json(); // Asegúrate de usar await aquí
      return data;
    } catch (error) {
      console.error("Error en la petición POST_FORMDATA:", error);
      throw error; // Asegúrate de lanzar el error
    }
  },

  put: async (endpoint, body) => {
    try {
      const response = await fetch(BASE_URL + endpoint, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          key: API_KEY,
          user: thisUserName,
          position: thisUserPosition,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error en la petición PUT:", error);
      throw error;
    }
  },

  delete: async (endpoint) => {
    try {
      const response = await fetch(BASE_URL + endpoint, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          key: API_KEY,
          user: thisUserName,
          position: thisUserPosition,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error en la petición DELETE:", error);
      throw error;
    }
  },
};

export default api;
