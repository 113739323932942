import { createContext, useContext, useState } from "react";
import moment from 'moment';

const AppContext = createContext();

export const AppProvider = ({ children }) => {

  const today = moment();
  const startMonth = today.clone().startOf('month'); 
  const oneYearAgo = today.clone().subtract(1, 'year'); 
  const startOfMonthOneYearAgo = oneYearAgo.clone().startOf('month');

  const [rol, setRol] = useState(null);
  const [groupToShow, setGroupToShow] = useState(null);
  const [dataToShow, setDataToShow] = useState(null);
  const [thisUser, setThisUser] = useState(null)

  const [groupData, setGroupData] = useState(null);
  const [hotelData, setHotelData] = useState(null);
  const [hotelList, setHotelList] = useState(null);
  const [departmentsData, setDepartmentsData] = useState(null);

  const [initDateFilter, setInitDateFilter] = useState(startMonth);
  const [endDateFilter, setEndDateFilter] = useState(today);
  const [intiDateFilterHistoric, setInitDateFilterHistoric] = useState(oneYearAgo);
  const [endDateFilterHistoric, setEndDateFilterHistoric] = useState(startOfMonthOneYearAgo);

  const [dailyInfoRequired, setDailyInfoRequired] = useState(null);
  


  const [hotelLogo, setHotelLogo] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState('FB');
  const [calendarVisible, setCalendarVisible] = useState(false);


  const [loading, setloading] = useState(false);
  const [hotelGroupSelected, setHotelGroupSelected] = useState(0);


  const [hotelSellPoints, setHotelSellPoints] = useState();
  const [periodicity, setPeriodicity] = useState(1);
  const [dataZoneView, setDataZoneView] = useState('dashboard')
  const [onlyOneHotel, setOnlyOneHotel] = useState(null);
  const [onlyOneGroup, setOnlyOneGroup] = useState(null);
  const [allGroups, setAllGroups] = useState(null);

  const [datosDashboard, setDatosDashboard] = useState(null);
  const [datosDashboardNew, setDatosDashboardNew] = useState(null);
  const [language, setLanguage] = useState('es');
  const [sellPointDetail, setSellPointDetail] = useState(null);
  const [objectivesView, setObjectivesView] = useState(false)

  return (
    <AppContext.Provider
      value={{
        onlyOneHotel,
        setOnlyOneHotel,
        onlyOneGroup, 
        setOnlyOneGroup,
        allGroups,
        setAllGroups,
        rol,
        setRol,
        thisUser,
        setThisUser,
        groupToShow,
        setGroupToShow,
        dataToShow,
        setDataToShow,
        groupData,
        setGroupData,
        hotelData,
        setHotelData,
        hotelList,
        setHotelList,
        departmentsData,
        setDepartmentsData,
        intiDateFilterHistoric, 
        setInitDateFilterHistoric,
        endDateFilterHistoric,
        setEndDateFilterHistoric,        
        initDateFilter,
        setInitDateFilter,
        setEndDateFilter,
        endDateFilter,
        dailyInfoRequired,
        setDailyInfoRequired,
        hotelLogo,
        setHotelLogo,
        selectedDepartment,
        setSelectedDepartment,
        calendarVisible,
        setCalendarVisible,
        loading,
        setloading,
        hotelGroupSelected,
        setHotelGroupSelected,
        hotelSellPoints,
        setHotelSellPoints,
        periodicity,
        setPeriodicity,
        dataZoneView,
        setDataZoneView,
        datosDashboard,
        setDatosDashboard,
        datosDashboardNew,
        setDatosDashboardNew,
        language,
        setLanguage,
        setSellPointDetail,
        sellPointDetail,
        objectivesView, 
        setObjectivesView,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
