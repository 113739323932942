import React, { useEffect, useState, useSyncExternalStore } from "react";
import "./MyProfile.css";
import { useAppContext } from "../../../Shared/Context/AppContext";
import dataGet from "../../../Shared/API/dataGet";
import dataPost from "../../../Shared/API/dataPost";
import dataPut from "../../../Shared/API/dataPut";
import dataDelete from "../../../Shared/API/dataDelete";
import eliminarIcon from "../../../Images/Icons/eliminar.png";
import nuevoIcon from "../../../Images/Icons/nuevo.png";
import nuevoInforme from "../../../Images/Icons/nuevoInforme.png";
import nuevoContacto from "../../../Images/Icons/nuevoContacto.png";
import editarIcon from "../../../Images/Icons/editarUsuario.png";
import guardarIcon from "../../../Images/Icons/guardar.png";
import cancelarIcon from "../../../Images/Icons/cancelar.png";
import NoPossibleAllHotels from "./NoPossibleAllHotels";

const MyProfile = ({hotelSelected}) => {
  const { hotelData, selectedDepartment, rol, thisUser, setThisUser } =
    useAppContext();
  const [myHotelData, setMyHotelData] = useState(null);
  const [mySellPoints, setMySellPoints] = useState([]);
  const [mySellPointsVisible, setMySellPointsVisible] = useState(false);
  const [myContacts, setMyContacts] = useState([]);
  const [newContactVisible, setNewContactVisible] = useState(false);
  const [myGroupContacts, setMygroupContacts] = useState([]);
  const [myStaff, setMyStaff] = useState([]);
  const [userDataVisible, setUserDataVisible] = useState(false);
  const [generalDataVisible, setGeneralDataVisible] = useState(false);
  const [departmentsVisible, setDepartmentsVisible] = useState(false);
  const [contactsVisible, setContactsVisible] = useState(false);
  const [staffVisible, setStaffVisible] = useState(false);
  const [newStaffVisible, setNewStaffVisible] = useState(false);
  const [contactsFilter, setContactsFilter] = useState(0);
  const [departments, setDepartments] = useState();
  const [newContact, setNewContact] = useState({});
  const [addReportVisible, setAddReportVisible] = useState(false);
  const [contactSelected, setContactSelected] = useState(null);
  const [newReportToAdd, setNewReportToAdd] = useState(null);
  const [reportTypes, setReportTypes] = useState([]);
  const [userDataIsEdit, setUserDataIsEdit] = useState(false);
  const [userDataToEdit, setUserDataToEdit] = useState(thisUser);
  const [messageError, setMessageError] = useState(null);

  useEffect(() => {
    if (hotelData) {
      setMyHotelData({ ...hotelData });
      getMyHotelContacts();
      getMyGroupContacts();
      getMyHotelStaff();

      dataGet
        .sellPointsByHotel(hotelData._id)
        .then((sellPointsResponse) => setMySellPoints(sellPointsResponse));

      dataGet.departments().then((departmentsResponse) => {
        setDepartments(departmentsResponse);
      });
    }
  }, [hotelData]);

  useEffect(() => {
    let reportList;
    if (selectedDepartment === "FB") {
      reportList = [
        {
          name: "Jefe departamento",
          value: "fbday",
        },
        {
          name: "Director/a",
          value: "fbweek",
        },
        {
          name: "Corporativo",
          value: "fbmonth",
        },
        {
          name: "Incentivos",
          value: "fbIncentives",
        },
      ];
    } else if (selectedDepartment === "FD") {
      reportList = [
        {
          name: "Jefe recepción",
          value: "fdday",
        },
        {
          name: "Director/a",
          value: "fdweek",
        },
        {
          name: "Corporativo",
          value: "fdmonth",
        },
        {
          name: "Incentivos",
          value: "fdIncentives",
        },
      ];
    }
    setReportTypes(reportList);
  }, [selectedDepartment]);

  const getMyHotelContacts = async () => {
    const contactResponse = await dataGet.hotelContacts(hotelData._id);
    if (contactResponse.length > 0) {
      contactResponse.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    }

    setMyContacts(contactResponse);
  };

  const getMyGroupContacts = async () => {
    const groupContactsResponse = await dataGet.groupContacts(
      hotelData.idHotelGroup._id
    );
    if (groupContactsResponse.length > 0) {
      groupContactsResponse.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    }

    setMygroupContacts(groupContactsResponse);
  };
  const getMyHotelStaff = async () => {
    const staffResponse = await dataGet.hotelStaff(hotelData._id);
    setMyStaff(staffResponse);
  };

  const fnSaveNew = async () => {
    if (contactsFilter === 1) {
      //contacto de cadena
      const objToSave = {
        group: hotelData.idHotelGroup._id,
        name: `${newContact.surname.toUpperCase()}, ${newContact.name.toUpperCase()}`,
        personalIdentifier: newContact.personalIdentifier,
        position: newContact.position,
        email: newContact.email,
        isActive: true,
      };
      await dataPost.groupContact(objToSave);
      getMyGroupContacts();
    }
    if (contactsFilter === 0) {
      //contacto de hotel
      const objToSave = {
        hotel: hotelData._id,
        name: `${newContact.surname.toUpperCase()}, ${newContact.name.toUpperCase()}`,
        personalIdentifier: newContact.personalIdentifier,
        position: newContact.position,
        email: newContact.email,
        isActive: true,
      };
      await dataPost.hotelContact(objToSave);
      getMyHotelContacts();
    }
  };
  const fnAddReport = async () => {
    if (
      newReportToAdd &&
      newReportToAdd !== "Nuevo informe" &&
      contactsFilter === 0 //contacto de hotel
    ) {
      const actualContact = myContacts.find(
        (item) => item._id === contactSelected
      );

      if (!actualContact) {
        console.error("Contact not found");
        return;
      }
      const myReports = actualContact.reports.map((item) => item.report);
      const newReportToAddLower = newReportToAdd.toLowerCase();

      myReports.push(newReportToAddLower);

      const reportToPush = {
        reports: myReports,
      };
      await dataPut.hotelContacts(contactSelected, reportToPush);
      getMyHotelContacts();
    }

    if (
      newReportToAdd &&
      newReportToAdd !== "Nuevo informe" &&
      contactsFilter === 1 //contacto de cadena
    ) {
      const actualContact = myGroupContacts.find(
        (item) => item._id === contactSelected
      );

      if (!actualContact) {
        console.error("Contact not found");
        return;
      }
      const myReports = actualContact.reports.map((item) => item.report);
      const newReportToAddLower = newReportToAdd.toLowerCase();

      myReports.push(newReportToAddLower);

      const reportToPush = {
        reports: myReports,
      };
      await dataPut.groupContacts(contactSelected, reportToPush);
      getMyGroupContacts();
    }
  };

  const fnFindContact = (id, type) => {
    let contact = null;
    if (type === 0) {
      contact = myContacts.find((item) => item._id === id);
    }
    if (type === 1) {
      contact = myGroupContacts.find((item) => item._id === id);
    }

    if (contact) {
      return contact.name;
    }
    return null;
  };

  const fnDeleteContact = async (item) => {
    const contactName = fnFindContact(item, contactsFilter);

    if (contactName) {
      // Mostrar el popup de confirmación
      const confirmDelete = window.confirm(
        `¿Estás seguro de que deseas eliminar a ${contactName}?`
      );

      if (confirmDelete) {
        if (contactsFilter === 0) {
          //contacto de hotel
          await dataDelete.deleteHotelContact(item);
          getMyHotelContacts();
        }
        if (contactsFilter === 1) {
          //contacto de cadena
          await dataDelete.deleteGroupContact(item);
          getMyGroupContacts();
        }
      }
    } else {
      console.error("Contacto no encontrado");
    }
  };

  // const fnFindContactToEdit = async(id, type)=>{
  //   if(type === 0){

  //   }
  // }
  const fnEditContact = async () => {
    try {
      const userId = userDataToEdit._id;

      // Esperar ambos llamados asíncronos con await
      const hotelContactsResponse = await dataGet.hotelContactsAll();
      const groupContactsResponse = await dataGet.groupContactsAll();

      // Obtener los identificadores personales de ambos conjuntos de datos
      const personalIdentifierHotel = hotelContactsResponse.map((item) => {
        return { personalIdentifier: item.personalIdentifier, id: item._id };
      });
      const personalIdentifierGroup = groupContactsResponse.map((item) => {
        return { personalIdentifier: item.personalIdentifier, id: item._id };
      });

      // Combinar ambos arrays en uno solo
      const allPersonalIdentifier = personalIdentifierGroup.concat(
        personalIdentifierHotel
      );
      const allPersonalIdentifierNoActual = allPersonalIdentifier.filter(
        (item) => item.id !== thisUser._id
      );
      const allPersonalIdentifierNoActualCode =
        allPersonalIdentifierNoActual.map((item) => item.personalIdentifier);
      if (
        allPersonalIdentifierNoActualCode.includes(
          userDataToEdit.personalIdentifier
        )
      ) {
        //EXITE UN IDENTIFICADOR IGUAL QUE NO CORRESPONDE AL USUARIO ACTUAL
        setMessageError("Identificador personal existente, debe elegir otro");
      } else {
        setMessageError(null);
        // Verificar si el contacto es de hotel o grupo
        if (userDataToEdit.hotel) {
          const putHotelContactResponse = await dataPut.hotelContacts(
            thisUser._id,
            userDataToEdit
          );
          setThisUser(putHotelContactResponse.new);
        } else if (userDataToEdit.group) {
          const putGroupContactResponse = await dataPut.groupContacts(
            thisUser._id,
            userDataToEdit
          );
          setThisUser(putGroupContactResponse.new);
        }
        setUserDataIsEdit(false);
      }
    } catch (error) {
      console.error("Error al obtener los contactos: ", error);
    }
  };

const fnNoPosibleAllHotels = () =>{
  if(hotelSelected === '1'){
    return <NoPossibleAllHotels/>
  }
}
  return (
    <div className="dataZone">
      {myHotelData && (
        <div className="myProfileContainer">
          <button
            onClick={() => setUserDataVisible(!userDataVisible)}
            className="itemsTitle"
          >
            Datos de usuario
          </button>
            <div>
              {!userDataIsEdit ? (
                <div className="iconDiv"
                onClick={() => setUserDataIsEdit(true)}>
                  <img src={editarIcon} alt="editar" title="Editar" />
                </div>
              ) : (
                <div className="iconsContainer">  
                  <div 
                  className="iconDiv"
                  onClick={() => fnEditContact()}>
                    <img src={guardarIcon} alt="editar" title="Editar" />
                  </div>
                  <div
                  className="iconDiv"
                    onClick={() => {
                      setUserDataIsEdit(false);
                      setUserDataToEdit(thisUser);
                    }}
                  >
                    <img src={cancelarIcon} alt="editar" title="Editar" />
                  </div>
                </div>
              )}
              {messageError && <p>{messageError}</p>}
              {thisUser && (
                <div className="myProfileContainer">
                  {userDataIsEdit ? (
                    <label className="labelEditProfile">
                      Nombre:{" "}
                      <input
                        className="myProfileEditInput"
                        placeholder="Nombre"
                        onChange={(e) =>
                          setUserDataToEdit((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }))
                        }
                        value={userDataToEdit.name}
                      />
                    </label>
                  ) : (
                    <p className="myProfileText">Nombre: {thisUser.name}</p>
                  )}
                  {userDataIsEdit ? (
                    <label className="labelEditProfile">
                      Cargo:{" "}
                      <input
                        className="myProfileEditInput"
                        placeholder="Cargo"
                        onChange={(e) =>
                          setUserDataToEdit((prev) => ({
                            ...prev,
                            position: e.target.value,
                          }))
                        }
                        value={userDataToEdit.position}
                      />
                    </label>
                  ) : (
                    <p className="myProfileText">Cargo: {thisUser.position}</p>
                  )}
                  {userDataIsEdit ? (
                    <label className="labelEditProfile">
                      Email:{" "}
                      <input
                        className="myProfileEditInput"
                        placeholder="Email"
                        onChange={(e) =>
                          setUserDataToEdit((prev) => ({
                            ...prev,
                            email: e.target.value,
                          }))
                        }
                        value={userDataToEdit.email}
                      />
                    </label>
                  ) : (
                    <p className="myProfileText">Email: {thisUser.email}</p>
                  )}
                  {userDataIsEdit ? (
                    <label className="labelEditProfile">
                      Identificador personal:{" "}
                      <input
                        className="myProfileEditInput"
                        placeholder="Identificador personal"
                        onChange={(e) =>
                          setUserDataToEdit((prev) => ({
                            ...prev,
                            personalIdentifier: e.target.value,
                          }))
                        }
                        value={userDataToEdit.personalIdentifier}
                      />
                    </label>
                  ) : (
                    <p className="myProfileText">
                      Identificador personal: {thisUser.personalIdentifier}
                    </p>
                  )}
                </div>
              )}
            </div>
      
          <button
            onClick={() => setGeneralDataVisible(!generalDataVisible)}
            className="itemsTitle"
          >
            Datos generales del hotel
          </button>

          {hotelSelected !== '1' ? (
            <div className="myProfileContainer">
              <p className="myProfileText">Nombre: {myHotelData.hotelName}</p>
              <p className="myProfileText">
                Dirección: {myHotelData.hotelAddress}
              </p>
              <p className="myProfileText">
                País: {myHotelData.hotelRegion.name}
              </p>
              <p className="myProfileText">
                Teléfono: {myHotelData.hotelPhone}
              </p>
              <p className="myProfileText">Web: {myHotelData.hotelWeb}</p>
              <p className="myProfileText">
                Segmento: {myHotelData.hotelType.hotelTypeName}
              </p>
              <div>
                {myHotelData.hotelCurrency.map((item, index) => (
                  <p className="myProfileText" key={index}>
                    Moneda: {item.currencyName}
                  </p>
                ))}
              </div>
              <p className="myProfileText">PMS: {myHotelData.PMS_POS}</p>
              <p className="myProfileText">
                Categoría: {myHotelData.hotelCategory.hotelCategoryName}
              </p>
            </div>
              ) : fnNoPosibleAllHotels()}
          <button
            onClick={() => setDepartmentsVisible(!departmentsVisible)}
            className="itemsTitle"
          >
            Departamentos
          </button>
            <div className="myProfileContainer">
              <h2>Departamentos</h2>

              <div className="departmentsContainer">
                {departments &&
                  departments.map((item, index) => (
                    <div key={index}>
                      <p
                        className={
                          myHotelData.departments.some(
                            (depItem) => depItem._id === item._id
                          )
                            ? "myProfileText "
                            : "myProfileText departmentNoContracted"
                        }
                      >
                        {item.departmentName}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
     

          {/* {selectedDepartment === "FB" && (
            <button
              onClick={() => setMySellPointsVisible(!mySellPointsVisible)}
              className="itemsTitle"
            >
              Puntos de venta
            </button>
          )} */}
          {/* <div className="myProfileContainer">
            <div className="contactsGroup">
              {mySellPointsVisible &&
                mySellPoints &&
                mySellPoints.length > 0 &&
                mySellPoints.map((item, index) => (
                  <div key={index} className="sellPoints">
                    <p>{item.sellPointName}</p>
                    <p className="sellPointsValues">
                      idPOS: {item.sellPointCode}
                    </p>
                    <p className="sellPointsValues">
                      nº empleados: {item.numberStaff}
                    </p>
                  </div>
                ))}
            </div>
          </div> */}

          {rol === "CON" ||
            (rol === "ADMIN" && (
              <button
                onClick={() => setContactsVisible(!contactsVisible)}
                className="itemsTitle"
              >
                Responsables
              </button>
            ))}

          <div className="myProfileContainer">
            {contactsVisible && (
              <div className="relative">
                {newContactVisible && (
                  <div className="new">
                    <h1>
                      {contactsFilter === 1
                        ? hotelData.idHotelGroup.name
                        : hotelData.hotelName}
                    </h1>
                    <input
                      placeholder="Apellidos"
                      onChange={(e) =>
                        setNewContact({
                          ...newContact,
                          surname: e.target.value,
                        })
                      }
                    />
                    <input
                      placeholder="Nombre"
                      onChange={(e) =>
                        setNewContact({
                          ...newContact,
                          name: e.target.value,
                        })
                      }
                    />
                    <input
                      placeholder="Identificador personal"
                      onChange={(e) =>
                        setNewContact({
                          ...newContact,
                          personalIdentifier: e.target.value,
                        })
                      }
                    />
                    <input
                      placeholder="Posición"
                      onChange={(e) =>
                        setNewContact({
                          ...newContact,
                          position: e.target.value,
                        })
                      }
                    />
                    <input
                      placeholder="Email"
                      onChange={(e) =>
                        setNewContact({
                          ...newContact,
                          email: e.target.value,
                        })
                      }
                    />
                    <button
                      onClick={() => {
                        setNewContactVisible(false);
                        setNewContact({});
                      }}
                    >
                      cancelar
                    </button>
                    <button
                      onClick={() => {
                        setNewContactVisible(false);
                        fnSaveNew();
                      }}
                    >
                      Guardar
                    </button>
                  </div>
                )}
                <div className="contactTypeSelector">
                  <label>Cadena</label>
                  <input
                    type="radio"
                    id="cadenaContact"
                    name="hotelGroupContact"
                    value="1"
                    checked={contactsFilter === 1}
                    onChange={() => setContactsFilter(1)}
                  />
                  <label>Hotel</label>
                  <input
                    type="radio"
                    id="hotelContact"
                    name="hotelGroupContact"
                    value="0"
                    checked={contactsFilter === 0}
                    onChange={() => setContactsFilter(0)}
                  />
                  <button
                    onClick={() => setNewContactVisible(!newContactVisible)}
                    className="btnNew"
                  >
                    <img
                      src={nuevoContacto}
                      alt="Nuevo"
                      title="Nuevo contacto"
                    />
                  </button>
                </div>

                <div className="contactsGroup">
                  {contactsFilter === 0 &&
                    myContacts &&
                    myContacts.map((item, index) => (
                      <div key={index} className="contacts">
                        <p>Nombre: {item.name}</p>
                        <p>Identificador: {item.personalIdentifier}</p>
                        <p>Posición: {item.position}</p>
                        <p>Email: {item.email}</p>
                        <label>
                          Activo
                          <input type="checkbox" checked={item.isActive} />
                        </label>
                        <button
                          className="contactOptionBtn"
                          onClick={() => {
                            setContactSelected(item._id);
                            fnDeleteContact(item._id);
                          }}
                        >
                          <img
                            src={eliminarIcon}
                            alt="eliminar"
                            title="borrar"
                          />
                        </button>
                        <div className="reportList">
                          <h3>Informes</h3>

                          {item.reports &&
                            item.reports.map((rep, repIndex) => {
                              return (
                                <div key={repIndex}>
                                  <p>
                                    {
                                      reportTypes.find(
                                        (item) => item.value === rep.report
                                      ).name
                                    }
                                  </p>
                                </div>
                              );
                            })}
                          <button
                            className="btnNew"
                            onClick={() => {
                              setAddReportVisible(!addReportVisible);
                              setContactSelected(item._id);
                            }}
                          >
                            <img
                              src={nuevoInforme}
                              alt="Nuevo"
                              title="Nuevo contacto"
                            />
                          </button>
                          {addReportVisible && contactSelected === item._id && (
                            <div className="">
                              <select
                                className="departmentSelector"
                                onChange={(e) =>
                                  setNewReportToAdd(e.target.value)
                                }
                              >
                                <option>Nuevo informe</option>
                                {reportTypes &&
                                  reportTypes.length > 0 &&
                                  reportTypes.map((item, index) => (
                                    <option key={index} value={item.value}>
                                      {item.name}
                                    </option>
                                  ))}
                              </select>
                              <button
                                className="newReportSaveBtn"
                                onClick={() => fnAddReport()}
                              >
                                <img
                                  src={nuevoIcon}
                                  alt="Nuevo"
                                  title="Nuevo contacto"
                                />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
                <div className="contactsGroup">
                  {contactsFilter === 1 &&
                    myGroupContacts &&
                    myGroupContacts.map((item, index) => (
                      <div key={index} className="contacts">
                        <p>Nombre: {item.name}</p>
                        <p>Identificador: {item.personalIdentifier}</p>
                        <p>Posición: {item.position}</p>
                        <p>Email: {item.email}</p>
                        <label>
                          Activo
                          <input
                            type="checkbox"
                            checked={item.isActive}
                            onChange={(e) => {
                              const updateContact = myGroupContacts.map(
                                (contact) =>
                                  contact.id === item.id
                                    ? { ...contact, isActive: e.target.checked }
                                    : contact
                              );
                              setMygroupContacts(updateContact); // Cambié setMyStaff por setMyGroupContacts
                            }}
                          />
                        </label>
                        <button
                          className="contactOptionBtn"
                          onClick={() => {
                            setContactSelected(item._id);
                            fnDeleteContact(item._id);
                          }}
                        >
                          <img
                            src={eliminarIcon}
                            alt="eliminar"
                            title="borrar"
                          />
                        </button>
                        <div className="reportList">
                          <h3>Informes</h3>
                          {item.reports &&
                            item.reports.map((rep, repIndex) => {
                              return (
                                <div key={repIndex}>
                                  <p>
                                    {
                                      reportTypes.find(
                                        (item) => item.value === rep.report
                                      ).name
                                    }
                                  </p>
                                </div>
                              );
                            })}
                          <button
                            onClick={() => {
                              setAddReportVisible(!addReportVisible);
                              setContactSelected(item._id);
                            }}
                            className="btnNew"
                          >
                            <img
                              src={nuevoInforme}
                              alt="Nuevo"
                              title="Nuevo contacto"
                            />
                          </button>
                          {addReportVisible && contactSelected === item._id && (
                            <div className="">
                              <select
                                className="departmentSelector"
                                onChange={(e) =>
                                  setNewReportToAdd(e.target.value)
                                }
                              >
                                <option>Nuevo informe</option>

                                {reportTypes &&
                                  reportTypes.length > 0 &&
                                  reportTypes.map((item, index) => (
                                    <option key={index} value={item.value}>
                                      {item.name}
                                    </option>
                                  ))}
                              </select>
                              <button
                                className="newReportSaveBtn"
                                onClick={() => fnAddReport()}
                              >
                                <img
                                  src={nuevoIcon}
                                  alt="Nuevo"
                                  title="Nuevo contacto"
                                />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
          {selectedDepartment !== "FB" && rol === "ADMIN" &&(
            <button
              onClick={() => setStaffVisible(!staffVisible)}
              className="itemsTitle"
            >
              Empleados
            </button>
          )}

          <div className="myProfileContainer">
            {staffVisible && (
              <div className="relative">
                {newStaffVisible && (
                  <div className="new">
                    <h1>{hotelData.hotelName}</h1>
                    <input placeholder="Apellidos" />
                    <input placeholder="Nombre" />
                    <input placeholder="Identificador personal" />
                    <input type="date" placeholder="Posición" />
                    <label>
                      Activo
                      <input type="checkbox" />
                    </label>
                    <label>
                      Lider
                      <input type="checkbox" />
                    </label>
                    <button onClick={() => setNewStaffVisible(false)}>
                      cancelar
                    </button>
                    <button onClick={() => setNewStaffVisible(false)}>
                      Guardar
                    </button>
                  </div>
                )}
                <div className="contactTypeSelector">
                  <button
                    className="btnNew"
                    onClick={() => setNewStaffVisible(true)}
                  >
                    <img
                      src={nuevoContacto}
                      alt="Nuevo"
                      title="Nuevo empleado"
                    />
                  </button>
                </div>
                {myStaff &&
                  myStaff.length > 0 &&
                  myStaff.map((item, index) => (
                    <div key={index} className="contacts">
                      <p className="contactName">Nombre: {item.staffName}</p>
                      <label>
                        Desde:
                        <input type="date" value={item.startDate} readOnly />
                      </label>

                      {item.endDate ? (
                        <div>
                          <label>
                            Hasta:
                            <input type="date" value={item.endDate} readOnly />
                          </label>
                          <button
                            onClick={() => {
                              const today = new Date();
                              const formattedDate = today
                                .toISOString()
                                .split("T")[0]; // Formato 'yyyy-mm-dd'
                              const updatedStaff = myStaff.map((staff) =>
                                staff.id === item.id
                                  ? {
                                      ...staff,
                                      endDate: null,
                                      isActive: true,
                                      startDate: formattedDate,
                                    }
                                  : staff
                              );
                              setMyStaff(updatedStaff);
                            }}
                          >
                            alta
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={() => {
                            const today = new Date();
                            const formattedDate = today
                              .toISOString()
                              .split("T")[0]; // Formato 'yyyy-mm-dd'

                            const updatedStaff = myStaff.map((staff) =>
                              staff.id === item.id
                                ? {
                                    ...staff,
                                    endDate: formattedDate,
                                    isActive: false,
                                  }
                                : staff
                            );
                            setMyStaff(updatedStaff);
                          }}
                        >
                          baja
                        </button>
                      )}

                      <p>Identificador: {item.staffIdentifier}</p>
                      <p>Email: {item.staffEmail}</p>

                      <label>
                        Activo
                        <input
                          type="checkbox"
                          checked={item.isActive}
                          onChange={(e) => {
                            const updatedStaff = myStaff.map((staff) =>
                              staff.id === item.id
                                ? { ...staff, isActive: e.target.checked }
                                : staff
                            );
                            setMyStaff(updatedStaff);
                          }}
                        />
                      </label>

                      <label>
                        Líder
                        <input
                          type="checkbox"
                          checked={item.isLeader}
                          onChange={(e) => {
                            const updatedStaff = myStaff.map((staff) =>
                              staff.id === item.id
                                ? { ...staff, isLeader: e.target.checked }
                                : staff
                            );
                            setMyStaff(updatedStaff);
                          }}
                        />
                      </label>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyProfile;
