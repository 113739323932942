import React, { useEffect, useState } from "react";
import "./DashboardView.css";
import "./SellPointsView.css"
import flechaVerde from "../../../Images/Icons/dasboard/flechaVerde.png";
import flechaRoja from "../../../Images/Icons/dasboard/flechaRoja.png";
import TopLastGraphic from "../../../Components/Graphics/TopLastGraphic";
import { useAppContext } from "../../../Shared/Context/AppContext";
import AllProducts from "./AllProducts";
import useLanguages from "../../../Shared/languages/setLanguages";
import AllScreen from "../../../Components/AllScreen/AllScreen";
import NoPossibleAllHotels from "../General/NoPossibleAllHotels";

const formatNumber = (number) => {
  const numberStr = Number(number).toFixed(2);
  const [numberWithoutDec, decNumber] = numberStr.split(".");
  const formattedInt = numberWithoutDec.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (decNumber !== "00") {
    return `${formattedInt},${decNumber}`;
  } else {
    return `${formattedInt}`;
  }
};

const SellPointsView = () => {
  const { hotelData, datosDashboardNew, rol, sellPointDetail, setDataZoneView } = useAppContext();
  const graphArr = Array.from({ length: 100 }, (_, index) => index + 1);
  const [allProductsVisible, setAllProductsVisible] = useState(false);
  const [sellPointObjectives, setSellPointObjectives] = useState(null);
  const [graphToShow, setGraphToShow] = useState("top");
  const [allScreen, setAllScreen] = useState(false);
  const [sellPointList, setSellPointList] = useState(null);
  const [sellPointSelected, setSellPointSelected] = useState(sellPointDetail);
  const [data, setData] = useState(null);

  const changeWord = useLanguages()

  const getObjective = (periodeObjective, sellPointName) => {
    const sellPointObjective = periodeObjective.find(
      (item) => item.sellPointName === sellPointName
    );

    return sellPointObjective.objective;
  };
  useEffect(() => {

    if (datosDashboardNew && datosDashboardNew.pointOfSaleIncome && datosDashboardNew.hotelName) {
      const sellPoints = datosDashboardNew.pointOfSaleIncome;
      const monthObjective = datosDashboardNew.pointOfSaleMonthObjective;
      const yearObjectives = datosDashboardNew.pointOfSaleYearObjective;
      const periodicity = datosDashboardNew.periodicity;
      const sellPointObjectivesArray = [];
      for (const sellPoint of sellPoints) {
        const sellPointName = sellPoint.pointOfSaleName;
        const sellPointIncome = sellPoint.pointOfSaleIncome;

        const objective =
          periodicity !== "100"
            ? getObjective(monthObjective, sellPoint.pointOfSaleName)
            : getObjective(yearObjectives, sellPoint.pointOfSaleName);
        sellPointObjectivesArray.push({
          sellPointName: sellPointName,
          sellPointIncome: sellPointIncome,
          objective: objective,
          percent: ((sellPointIncome * 100) / objective).toFixed(),
        });
      }

      setSellPointObjectives(sellPointObjectivesArray);

      const pointsOfSaleList = datosDashboardNew.pointOfSaleIncome.map((item) => item.pointOfSaleName);
      setSellPointList(pointsOfSaleList)
    }
  }, [datosDashboardNew]);

  const findPointOfSaleData = (data, pointOfSale, property) => {
    if (property === 'pointOfSaleTickets') {
      console.log(data)
    }
    const fnResult = data.find((item) => {
      if (item && item.pointOfSaleName) {
        return item.pointOfSaleName === pointOfSale
      }
      if (item && item.sellPointName) {
        return item.sellPointName === pointOfSale
      }
      if (item && item.sellPoint) {
        return item.sellPoint === pointOfSale
      }
      return false
    });
    if (fnResult) {
      return fnResult[property];
    } else {
      return 0;
    }
  };
  const findPointOfSaleTopLast = (data, pointOfSale) => {
    const fnResult = data.find((item) => item.sellPoint === pointOfSale);
    return fnResult
  }
  useEffect(() => {
    if (sellPointSelected && datosDashboardNew && datosDashboardNew.hotelName) {
      const totalSellPointIncome = findPointOfSaleData(datosDashboardNew.pointOfSaleIncome, sellPointSelected, 'pointOfSaleIncome');
      const preTotalSellPointIncome = findPointOfSaleData(datosDashboardNew.prePointOfSaleIncome, sellPointSelected, 'pointOfSaleIncome');
      const totalSellPointIncomeDif = findPointOfSaleData(datosDashboardNew.pointOfSaleIncomeDif, sellPointSelected, 'dif');
      const totalSellPointIncomeDifPercent = findPointOfSaleData(datosDashboardNew.pointOfSaleIncomeDif, sellPointSelected, 'difPercent');

      const totalSellPointUnits = findPointOfSaleData(datosDashboardNew.pointOfSaleUnits, sellPointSelected, 'pointOfSaleUnits');
      const preTotalSellPointUnits = findPointOfSaleData(datosDashboardNew.prePointOfSaleUnits, sellPointSelected, 'pointOfSaleUnits');
      const totalSellPointUnitsDif = findPointOfSaleData(datosDashboardNew.pointOfSaleUnitsDif, sellPointSelected, 'dif');
      const totalSellPointUnitsDifPercent = findPointOfSaleData(datosDashboardNew.pointOfSaleUnitsDif, sellPointSelected, 'difPercent');

      const numberStaffSellPoint = findPointOfSaleData(datosDashboardNew.sellPointStaff, sellPointSelected, 'sellPointStaff');
      const topLastProducts = findPointOfSaleTopLast(datosDashboardNew.topLastProductsSellPoint, sellPointSelected);

      const sellPointTickets = findPointOfSaleData(datosDashboardNew.pointOfSaleTickets, sellPointSelected, 'pointOfSaleTickets');
      const preSellPointTickets = findPointOfSaleData(datosDashboardNew.prePointOfSaleTickets, sellPointSelected, 'prePointOfSaleTickets');

      const hotelStances = parseInt(datosDashboardNew.hotelStances);
      const preHotelStances = parseInt(datosDashboardNew.preHotelStances);
      const hotelStancesDif = parseInt(datosDashboardNew.hotelStancesDif);
      const hotelStancesDifPercent = parseFloat(datosDashboardNew.hotelStancesDifPercent);
      const averageItemSetted = parseFloat(datosDashboardNew.averageItemSetted);

      const sellPointIncomePerStaff = parseInt(numberStaffSellPoint) !== 0 ? parseFloat(totalSellPointIncome) / parseInt(numberStaffSellPoint) : 0;
      const preSellPointIncomePerStaff = parseInt(numberStaffSellPoint) !== 0 ? parseFloat(preTotalSellPointIncome) / parseInt(numberStaffSellPoint) : 0;
      const sellPointIncomePerStaffDif = sellPointIncomePerStaff - preSellPointIncomePerStaff;
      const sellPointIncomePerStaffDifPercent = preSellPointIncomePerStaff !== 0 ? (sellPointIncomePerStaff * 100) / 100 : 100;

      const sellPointIncomePerStance = hotelStances !== 0 ? totalSellPointIncome / hotelStances : 0;
      const preSellPointIncomePerStance = preHotelStances !== 0 ? preTotalSellPointIncome / preHotelStances : 0;
      const sellPointIncomePerStanceDif = sellPointIncomePerStance - preSellPointIncomePerStance;
      const sellPointIncomePerStanceDifPercent = preSellPointIncomePerStance !== 0 ? (sellPointIncomePerStance * 100) / preSellPointIncomePerStance : 100;

      const sellPointAverageSellItem = parseFloat(totalSellPointUnits) !== 0 ? parseFloat(totalSellPointIncome) / parseFloat(totalSellPointUnits) : 0;

      const sellPointAverageSellItemDif = sellPointAverageSellItem - averageItemSetted;
      const sellPointAverageSellItemDifPercent = parseFloat(averageItemSetted) !== 0 ? (parseFloat(sellPointAverageSellItem) * 100) / parseFloat(averageItemSetted) : 100;

      const objToData = {
        sellPointIncome: totalSellPointIncome,
        preSellPointIncome: preTotalSellPointIncome,
        sellPointIncomeDif: totalSellPointIncomeDif,
        sellPointIncomeDifPercent: totalSellPointIncomeDifPercent,
        sellPointUnits: totalSellPointUnits,
        preSellPointUnits: preTotalSellPointUnits,
        sellPointUnitsDif: totalSellPointUnitsDif,
        sellPointUnitsDifPercent: totalSellPointUnitsDifPercent,
        hotelStances: hotelStances,
        preHotelStances: preHotelStances,
        hotelStancesDif: hotelStancesDif,
        hotelStancesDifPercent: hotelStancesDifPercent,
        incomePerStaff: sellPointIncomePerStaff,
        preIncomePerStaff: preSellPointIncomePerStaff,
        incomePerStaffDif: sellPointIncomePerStaffDif,
        incomePerStaffDifPercent: sellPointIncomePerStaffDifPercent,
        incomePerStance: sellPointIncomePerStance,
        preIncomePerStance: preSellPointIncomePerStance,
        incomePerStanceDif: sellPointIncomePerStanceDif,
        incomePerStanceDifPercent: sellPointIncomePerStanceDifPercent,
        averageSellItem: sellPointAverageSellItem,
        averageSetted: averageItemSetted,
        averageSellItemDif: sellPointAverageSellItemDif,
        averageSellItemDifPercent: sellPointAverageSellItemDifPercent,
        topLastProducts: topLastProducts,
      }
      setData(objToData);

    }
  }, [sellPointSelected, datosDashboardNew])
  return (
    <div className={rol !== 'ADMIN' && allScreen ? "dataZone allScreen" :
      rol !== 'ADMIN' && !allScreen ? 'dataZone' :
        rol === "ADMIN" && allScreen ? 'dataZoneAdmin allScreen' : 'dataZoneAdmin'}>
      {/* <AllScreen setAllScreen={setAllScreen} allScreen={allScreen} /> */}
      <div className="sellPointName_menu">
        <button onClick={() => setDataZoneView('dashboard')}>volver a datos del hotel</button>
        <p>{sellPointSelected}</p>
      </div>


      {data &&
        data.sellPointIncome &&
        datosDashboardNew.hotelName
        ? (
          <div className="dataZoneContainer">
            <div className="dataZoneRowGraph">
              {data.sellPointIncomeDif &&
                data.sellPointIncomeDifPercent !==
                undefined && (
                  <div className="dataZoneCard">
                    <div className="dataZoneCardTitle">
                      <p>{changeWord('ingresosTotales')}</p>
                    </div>
                    <div className="dataZoneCardItemGroupFirst">
                      <div className="dataZoneCardItem">
                        <p className="dataZoneCardItemD">
                          {sellPointSelected &&
                            data.sellPointIncome &&
                            formatNumber(data.sellPointIncome)
                          }€

                        </p>
                      </div>
                      <div className="dataZoneCardItem">
                        {data.sellPointIncome >
                          data.preSellPointIncome ? (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImage"
                                src={flechaVerde}
                                alt="arriba"
                              />
                            </div>
                            <div className="dataZoneCardItemText">
                              {data.pointOfSaleIncome >
                                data.prePointOfSaleIncome ? (
                                <div>
                                  <p className="dataZoneCardItemA green">
                                    {formatNumber(
                                      data.sellPointIncomeDif
                                    )}
                                    €
                                  </p>

                                  <p className="dataZoneCardItemB green">
                                    {formatNumber(
                                      data.sellPointIncomeDifPercent
                                    )}
                                    %
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <p className="dataZoneCardItemA red">
                                    {formatNumber(data.sellPointIncomeDif)}€
                                  </p>
                                  <p className="dataZoneCardItemB red">
                                    {formatNumber(data.sellPointIncomeDifPercent)}
                                    %
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImageBad"
                                src={flechaRoja}
                                alt="arriba"
                              />
                            </div>
                            {data.sellPointIncomeDif &&
                              data.sellPointIncomeDifPercent && (
                                <div className="dataZoneCardItemText">
                                  {data.sellpointincome >
                                    data.preSellPointIncome ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {formatNumber(
                                          data.sellPointIncomeDif

                                        )}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {formatNumber(
                                          data.sellPointIncomeDifPercent
                                        )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {formatNumber(
                                          data.sellPointIncomeDif
                                        )}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {formatNumber(
                                          data.sellPointIncomeDifPercent
                                        )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                    <p className="dataZoneCardItemC">
                      {changeWord('ventasPerdiodoAnterior')}:{" "}
                      {formatNumber(data.preSellPointIncome)}€
                    </p>
                  </div>
                )}

              {data.sellPointUnitsDif &&
                data.sellPointUnitsDifPercent && (
                  <div className="dataZoneCard">
                    <div className="dataZoneCardTitle">
                      <p>{changeWord('unidadesVendidas')}</p>
                    </div>
                    <div className="dataZoneCardItemGroupFirst">
                      <div className="dataZoneCardItem">
                        <p className="dataZoneCardItemD">
                          {sellPointSelected && formatNumber(data.sellPointUnits)}
                        </p>
                      </div>
                      <div className="dataZoneCardItem">
                        {data.sellPointUnits >
                          data.preSellPointUnits ? (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImage"
                                src={flechaVerde}
                                alt="arriba"
                              />
                            </div>

                            <div className="dataZoneCardItemText">
                              {data.sellPointUnits >
                                data.preSellPointUnits ? (
                                <div>
                                  <p className="dataZoneCardItemA green">
                                    {formatNumber(
                                      data.sellPointUnitsDif
                                    )}
                                    Uds
                                  </p>

                                  <p className="dataZoneCardItemB green">
                                    {formatNumber(
                                      data.sellPointUnitsDifPercent
                                    )}
                                    %
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <p className="dataZoneCardItemA red">
                                    {data.sellPointUnitsDif}Uds
                                  </p>
                                  <p className="dataZoneCardItemB red">
                                    {formatNumber(
                                      data.sellPointUnitsDifPercent
                                    )}
                                    %
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImageBad"
                                src={flechaRoja}
                                alt="arriba"
                              />
                            </div>
                            <div className="dataZoneCardItemText">
                              {data.sellPointUnits >
                                data.preSellPointUnits ? (
                                <div>
                                  <p className="dataZoneCardItemA green">
                                    {data.sellPointUnitsDif}
                                  </p>

                                  <p className="dataZoneCardItemB green">
                                    {formatNumber(
                                      data.sellPointUnitsDifPercent
                                    )}
                                    %
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <p className="dataZoneCardItemA red">
                                    {data.sellPointUnitsDif}Uds
                                  </p>
                                  <p className="dataZoneCardItemB red">
                                    {formatNumber(
                                      data.sellPointUnitsDifPercent
                                    )}
                                    %
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <p className="dataZoneCardItemC">
                      {changeWord('unidadesVendidasPeriodoAnterior')}:{" "}
                      {formatNumber(data.preSellPointUnits)}Uds
                    </p>
                  </div>
                )}
              {parseFloat(data.hotelStancesDif) &&
                data.hotelStancesDifPercent && (
                  <div className="dataZoneCard">
                    <div className="dataZoneCardTitle">
                      <p>{changeWord('estancias')}</p>
                    </div>
                    <div className="dataZoneCardItemGroupFirst">
                      <div className="dataZoneCardItem">
                        <p className="dataZoneCardItemD">
                          {formatNumber(data.hotelStances)}
                        </p>
                      </div>
                      <div className="dataZoneCardItem">
                        {parseInt(data.hotelStances) >
                          parseInt(data.preHotelStances) ? (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImage"
                                src={flechaVerde}
                                alt="arriba"
                              />
                            </div>
                            <div className="dataZoneCardItemText">
                              {parseInt(data.hotelStances) >
                                parseInt(data.preHotelStances) ? (
                                <div>
                                  <p className="dataZoneCardItemA green">
                                    {formatNumber(data.hotelStancesDif)}
                                  </p>

                                  <p className="dataZoneCardItemB green">
                                    {formatNumber(
                                      data.hotelStancesDifPercent
                                    )}
                                    %
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <p className="dataZoneCardItemA red">
                                    {formatNumber(data.hotelStancesDif)}
                                    €
                                  </p>
                                  <p className="dataZoneCardItemB red">
                                    {formatNumber(
                                      data.hotelStancesDifPercent
                                    )}
                                    %
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImageBad"
                                src={flechaRoja}
                                alt="arriba"
                              />
                            </div>
                            <div className="dataZoneCardItemText">
                              {data.hotelStances &&
                                parseInt(data.hotelStances) >
                                parseInt(data.preHotelStances) ? (
                                <div>
                                  <p className="dataZoneCardItemA green">
                                    {formatNumber(data.hotelStancesDif)}
                                  </p>

                                  <p className="dataZoneCardItemB green">
                                    {formatNumber(
                                      data.hotelStancesDifPercent
                                    )}
                                    %
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <p className="dataZoneCardItemA red">
                                    {data.hotelStancesDif &&
                                      data.hotelStancesDif}
                                    €
                                  </p>
                                  <p className="dataZoneCardItemB red">
                                    {formatNumber(
                                      data.hotelStancesDifPercent
                                    )}
                                    %
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <p className="dataZoneCardItemC">
                      {changeWord('estanciasPeriodoAnterior')}:{" "}
                      {formatNumber(data.preHotelStances)}
                    </p>
                  </div>
                )}
            </div>
            <div className="dataZoneRowGraph">
              <div className="sellPointsIncomeVsObjective">
                <div className="kpis">
                  {data.incomePerStaffDif !== undefined &&
                    data.incomePerStaffDifPercent !== undefined && (
                      <div className="dataZoneCard">
                        <div className="dataZoneCardTitle">
                          <p>{changeWord('ventasPorEmpleado')}</p>
                        </div>
                        <div className="dataZoneCardItemGroup">
                          <div className="dataZoneCardItem">
                            <p className="dataZoneCardItemD">
                              {sellPointSelected && formatNumber(data.incomePerStaff)}€
                            </p>
                          </div>
                          <div className="dataZoneCardItem">
                            {data.incomePerStaff >
                              data.preIncomePerStaff ? (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImage"
                                    src={flechaVerde}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {parseInt(data.incomePerStaff) >
                                    parseInt(data.preIncomePerStaff) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {data.incomePerStaffDif &&
                                          formatNumber(
                                            data.incomePerStaffDif
                                          )}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {data.incomePerStaffDifPercent &&
                                          formatNumber(
                                            data.incomePerStaffDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {data.incomePerStaffDif &&
                                          formatNumber(
                                            data.incomePerStaffDif
                                          )}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {data.incomePerStaffDifPercent &&
                                          formatNumber(
                                            data.incomePerStaffDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImageBad"
                                    src={flechaRoja}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {data.incomePerStaff &&
                                    data.incomePerStaff >
                                    data.preIncomePerStaff ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {data.incomePerStaffDif &&
                                          formatNumber(
                                            data.incomePerStaffDif
                                          )}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {data.incomePerStaffDifPercent &&
                                          formatNumber(
                                            data.incomePerStaffDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {data.incomePerStaffDif &&
                                          formatNumber(
                                            data.incomePerStaffDif
                                          )}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {data.incomePerStaffDifPercent &&
                                          formatNumber(
                                            data.incomePerStaffDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <p className="dataZoneCardItemC">
                          {changeWord('ventasEmpleadoPeriodoAnterior')}:{" "}
                          {data.preIncomePerStaff &&
                            formatNumber(data.preIncomePerStaff)}
                          €
                        </p>
                      </div>
                    )}

                  {datosDashboardNew.ticketAverageDif !== undefined &&
                    datosDashboardNew.ticketAverageDifPercent !== undefined && (
                      <div className="dataZoneCard">
                        <div className="dataZoneCardTitle">
                          <p>{changeWord('ticketMedio')}</p>
                        </div>
                        <div className="dataZoneCardItemGroup">
                          <div className="dataZoneCardItem">
                            <p className="dataZoneCardItemD">
                              {sellPointSelected && (() => {
                                const incomeItem = datosDashboardNew.pointOfSaleIncome.find((item) => item.pointOfSaleName === sellPointSelected);
                                const unitItem = datosDashboardNew.pointOfSaleUnits.find((item) => item.pointOfSaleName === sellPointSelected);

                                // Asegurarse de que ambos items existen y tienen valores válidos antes de hacer la división
                                if (incomeItem && unitItem && unitItem.pointOfSaleUnits !== 0) {
                                  const income = incomeItem.pointOfSaleIncome;
                                  const units = unitItem.pointOfSaleUnits;
                                  const result = income / units;
                                  const formattedResult = formatNumber(result)
                                  return `${formattedResult} €`;
                                }

                                return "N/A"; // Si no se encuentra algún dato, mostrar 'N/A'
                              })()}


                            </p>
                          </div>
                          <div className="dataZoneCardItem">
                            {datosDashboardNew.ticketAverage >
                              datosDashboardNew.preTicketAverage ? (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImage"
                                    src={flechaVerde}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {parseInt(datosDashboardNew.ticketAverage) >
                                    parseInt(datosDashboardNew.preTicketAverage) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {datosDashboardNew.ticketAverageDif &&
                                          formatNumber(
                                            datosDashboardNew.ticketAverageDif
                                          )}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {datosDashboardNew.ticketAverageDifPercent &&
                                          formatNumber(
                                            datosDashboardNew.ticketAverageDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {datosDashboardNew.ticketAverageDif &&
                                          formatNumber(
                                            datosDashboardNew.ticketAverageDif
                                          )}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {datosDashboardNew.ticketAverageDifPercent &&
                                          formatNumber(
                                            datosDashboardNew.ticketAverageDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImageBad"
                                    src={flechaRoja}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {datosDashboardNew.ticketAverage &&
                                    parseInt(datosDashboardNew.ticketAverage) >
                                    parseInt(datosDashboardNew.preTicketAverage) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {datosDashboardNew.ticketAverageDif &&
                                          formatNumber(
                                            datosDashboardNew.ticketAverageDif
                                          )}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {datosDashboardNew.ticketAverageDifPercent &&
                                          formatNumber(
                                            datosDashboardNew.ticketAverageDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {datosDashboardNew.ticketAverageDif &&
                                          formatNumber(
                                            datosDashboardNew.ticketAverageDif
                                          )}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {datosDashboardNew.ticketAverageDifPercent &&
                                          formatNumber(
                                            datosDashboardNew.ticketAverageDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <p className="dataZoneCardItemC">
                          {changeWord('ticketMedioPeriodoAnterior')}:{" "}
                          {datosDashboardNew.preTicketAverage &&
                            formatNumber(datosDashboardNew.preTicketAverage)}
                          €
                        </p>
                      </div>
                    )}
                </div>
              </div>
              <div className="sellPointsIncomeVsObjective">
                <div className="kpis kpisMobile">
                  {data.incomePerStanceDif &&
                    data.incomePerStanceDifPercent && (
                      <div className="dataZoneCard">
                        <div className="dataZoneCardTitle">
                          <p>{changeWord('ingresoPorEstancia')}</p>
                        </div>
                        <div className="dataZoneCardItemGroup">
                          <div className="dataZoneCardItem">
                            <p className="dataZoneCardItemD">
                              {data.incomePerStance &&
                                formatNumber(data.incomePerStance)}
                              €
                            </p>
                          </div>
                          <div className="dataZoneCardItem">
                            {parseFloat(data.incomePerStance) >
                              parseFloat(data.preIncomePerStance) ? (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImage"
                                    src={flechaVerde}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {parseFloat(data.incomePerStance) >
                                    parseFloat(
                                      data.preIncomePerStance
                                    ) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {data.incomePerStanceDif &&
                                          formatNumber(
                                            data.incomePerStanceDif
                                          )}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {data.incomePerStanceDifPercent &&
                                          formatNumber(
                                            data.incomePerStanceDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {data.incomePerStanceDif &&
                                          formatNumber(
                                            data.incomePerStanceDif
                                          )}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {data.incomePerStanceDifPercent &&
                                          formatNumber(
                                            data.incomePerStanceDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImageBad"
                                    src={flechaRoja}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {data.incomePerStance &&
                                    parseInt(data.incomePerStance) >
                                    parseInt(
                                      data.preIncomePerStance
                                    ) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {data.incomePerStanceDif &&
                                          formatNumber(
                                            data.incomePerStanceDif
                                          )}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {data.incomePerStanceDifPercent &&
                                          formatNumber(
                                            data.incomePerStanceDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {data.incomePerStanceDif &&
                                          formatNumber(
                                            data.incomePerStanceDif
                                          )}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {data.incomePerStanceDifPercent &&
                                          formatNumber(
                                            data.incomePerStanceDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <p className="dataZoneCardItemC">
                          {changeWord('ticketMedioPeriodoAnterior')}:{" "}
                          {data.preIncomePerStance &&
                            formatNumber(data.preIncomePerStance)}
                          €
                        </p>
                      </div>
                    )}
                  {data.averageSellItemDif &&
                    data.averageSellItemDifPercent && (
                      <div className="dataZoneCard">
                        <div className="dataZoneCardTitle">
                          <p>{changeWord('precioPromedioVendido')}</p>
                        </div>
                        <div className="dataZoneCardItemGroup">
                          <div className="dataZoneCardItem">
                            <p className="dataZoneCardItemD">
                              {formatNumber(data.averageSellItem)}
                            </p>
                          </div>
                          <div className="dataZoneCardItem">
                            {parseFloat(data.averageSellItem) >
                              parseFloat(data.averageSetted) ? (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImage"
                                    src={flechaVerde}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {parseInt(data.averageSellItem) >
                                    parseInt(data.averageSetted) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {data.averageSellItemDif &&
                                          formatNumber(
                                            data.averageSellItemDif
                                          )}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {data.averageSellItemDifPercent &&
                                          formatNumber(
                                            data.averageSellItemDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {data.averageSellItemDif &&
                                          formatNumber(
                                            data.averageSellItemDif
                                          )}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {data.averageSellItemDifPercent &&
                                          formatNumber(
                                            data.averageSellItemDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImageBad"
                                    src={flechaRoja}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {data.averageSellItem &&
                                    parseInt(data.averageSellItem) >
                                    parseInt(data.averageSetted) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {data.averageSellItemDif &&
                                          formatNumber(
                                            data.averageSellItemDif
                                          )}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {data.averageSellItemDifPercent &&
                                          formatNumber(
                                            data.averageSellItemDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {data.averageSellItemDif &&
                                          formatNumber(
                                            data.averageSellItemDif
                                          )}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {data.averageSellItemDifPercent &&
                                          formatNumber(
                                            data.averageSellItemDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <p className="dataZoneCardItemC">
                          {changeWord('precioPromedioEstablecido')}:{" "}
                          {data.averageSetted &&
                            formatNumber(data.averageSetted)}
                          €
                        </p>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="dataZoneRowGraph">
              <div className="sellPointsIncomeVsObjective">
                <div className="sellPointItem thisTitle">
                  <div className="contributionTitle">
                    <div className="contributionTitle">
                      <p className='contributionTitleProd'>Punto de venta</p>
                      <p className='sellPointIncomeValue'>{changeWord('produccionPorPuntoDeVenta')}</p>
                      <p className='contributionTitleRat'>{changeWord('ratioDeContribucion')}</p>
                    </div>
                  </div>
                </div>
                {sellPointObjectives &&
                  sellPointObjectives.length > 0 &&
                  sellPointObjectives
                    .filter((item) => item.sellPointName === sellPointSelected)
                    .map((item, index) => (
                      <div key={index} className="contributionTitle">
                        <p className='contributionTitleProd cursorPointer'>{item.sellPointName}</p>
                        <p className='sellPointIncomeValue cursorPointer'>{formatNumber(item.sellPointIncome)}€</p>
                        <p className='contributionTitleRat cursorPointer'>{formatNumber((item.sellPointIncome) * 100 / datosDashboardNew.totalHotelIncome)}%</p>

                      </div>
                    ))}
              </div>


              <div className="sellPointsIncomeVsObjective">
                <div className="dataZoneCardTitle">
                  <div className="rankingTitle">
                    <p>{changeWord('rankingDeProductos')}</p>
                  </div>
                </div>
                <div className="rankingProductosAndSelector">
                  {data.topLastProducts.top && data.topLastProducts.last &&
                    (
                      <TopLastGraphic
                        data1={
                          graphToShow === "top"
                            ? data.topLastProducts.top
                            : graphToShow === "last"
                              ? data.topLastProducts.last
                              : data.topLastProducts.top
                        }
                      />
                    )}
                  <div className="rankingProductosSelector">
                    <p
                      onClick={() => setGraphToShow("top")}
                      className={
                        graphToShow === "top"
                          ? "rankingProductosSelectorItem rankingProductosSelectedSelect"
                          : "rankingProductosSelectorItem"
                      }
                    >
                      top
                    </p>
                    <p
                      onClick={() => setGraphToShow("last")}
                      className={
                        graphToShow === "last"
                          ? "rankingProductosSelectorItem rankingProductosSelectedSelect"
                          : "rankingProductosSelectorItem"
                      }
                    >
                      last
                    </p>
                    <p
                      onClick={() => setAllProductsVisible(true)}
                      className="rankingProductosSelectorItem"
                    >
                      {changeWord('todos')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {allProductsVisible && (
              <AllProducts
                setAllProductsVisible={setAllProductsVisible}
                data={datosDashboardNew.topLastProductsSellPoint.find((item) => item.sellPoint === sellPointSelected).all}
                title={hotelData.hotelName}
              />
            )}
          </div>
        ) : (
          <div className="noData">
            {!datosDashboardNew.hotelName ? <NoPossibleAllHotels /> : <h1>SIN DATOS</h1>}

          </div>
        )}
    </div>
  );
};

export default SellPointsView;
