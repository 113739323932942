import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const backGroundColorGraph = [
  'rgba(204, 0, 102, 1)',    // Color 1 - Income (intenso)
  'rgba(34, 139, 34, 1)',    // Color 2 - Income (intenso)
  'rgba(246, 94, 10, 1)',    // Color 3 - Income (intenso)
  'rgba(10, 117, 198, 1)',   // Color 4 - Income (intenso)
  'rgba(169, 169, 169, 1)',  // Color 5 - Income (intenso)
  'rgba(255, 165, 0, 1)',    // Color 6 - Income (intenso)
  'rgba(75, 0, 130, 1)',     // Color 7 - Income (intenso)
  'rgba(220, 20, 60, 1)',    // Color 8 - Income (intenso)
  'rgba(50, 205, 50, 1)',    // Color 9 - Income (intenso)
  'rgba(255, 215, 0, 1)'     // Color 10 - Income (intenso)
];

const backGroundColorGraphUnits = [
  'rgba(204, 0, 102, 0.5)',  // Color 1 - Units (menos intenso)
  'rgba(34, 139, 34, 0.5)',  // Color 2 - Units (menos intenso)
  'rgba(246, 94, 10, 0.5)',  // Color 3 - Units (menos intenso)
  'rgba(10, 117, 198, 0.5)', // Color 4 - Units (menos intenso)
  'rgba(169, 169, 169, 0.5)',// Color 5 - Units (menos intenso)
  'rgba(255, 165, 0, 0.5)',  // Color 6 - Units (menos intenso)
  'rgba(75, 0, 130, 0.5)',   // Color 7 - Units (menos intenso)
  'rgba(220, 20, 60, 0.5)',  // Color 8 - Units (menos intenso)
  'rgba(50, 205, 50, 0.5)',  // Color 9 - Units (menos intenso)
  'rgba(255, 215, 0, 0.5)'   // Color 10 - Units (menos intenso)
];

const FdRankingCrossellingGraph = ({ dataSet, type }) => {
  let filteredData;

  if (type === 'all') {
    filteredData = [...dataSet.reserve, ...dataSet.pax];  // Combina los datos de reserve y pax
  } else if (type === 'reserve') {
    filteredData = dataSet.reserve;  // Filtra solo los datos de reserve
  } else if (type === 'pax') {
    filteredData = dataSet.pax;  // Filtra solo los datos de pax
  }

  filteredData.sort((a, b) => b.income - a.income);
  
  const labels = filteredData.map(item => item.concept);  // Mapea los conceptos
  const incomeData = filteredData.map(item => item.income);  // Mapea los ingresos
  const unitsData = filteredData.map(item => item.units);  // Mapea las unidades

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Ingresos (€)',
        data: incomeData,
        backgroundColor: backGroundColorGraph,
        borderColor: backGroundColorGraph,
        borderWidth: 1,
        yAxisID: 'y-axis-income',  // Eje para ingresos
      },
      {
        label: 'Unidades',
        data: unitsData,
        backgroundColor: backGroundColorGraphUnits,
        borderColor: backGroundColorGraphUnits,
        borderWidth: 1,
        yAxisID: 'y-axis-units',  // Eje para unidades
      }
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 16,
            weight: 'bold',
          }
        }
      },
      'y-axis-income': {
        type: 'linear',
        position: 'left',
        ticks: {
          callback: function (value) {
            return value + '€'; // Formatear los valores de income
          },
        },
        title: {
          display: true,
          text: 'Ingresos (€)',
        },
      },
      'y-axis-units': {
        type: 'linear',
        position: 'right',
        ticks: {
          callback: function (value) {
            return value + ' uds'; // Formatear los valores de units
          },
        },
        title: {
          display: true,
          text: 'Unidades',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom', // Leyenda abajo
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default FdRankingCrossellingGraph;
