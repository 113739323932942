import React, { useState } from 'react'
import { useAppContext } from '../../../Shared/Context/AppContext'
import './Chat.css'

const Chat = () => {
    const { rol, setDataZoneView } = useAppContext();
    const [allScreen, setAllScreen] = useState(false);
    const [chatDestination, setChatDestination] = useState('0');
    const [originMail, setOriginMail] = useState('');
    const [originName, setOriginName] = useState('');
    const [message, setMessage] = useState('');
    const [confirmation, setConfirmation] = useState(null);
    const [enviarVisible, setEnviarVisible] = useState(true);
    const [formVisible, setFormVisible] = useState(true)

    const fnSendMail = () => {
        const objToSend = {
            destination: chatDestination,
            originMail: originMail,
            originName: originName,
            message: message,
        }

        //CÓDIGO PARA ENVIAR EL MAIL 

        const confirmationText = `  HEMOS RECIBIDO TU MENSAJE \n nos pondremos en contacto contigo lo más pronto posible`
        setConfirmation(confirmationText)
        setEnviarVisible(false)
        setFormVisible(false)
    }
    return (
        <div className={rol !== 'ADMIN' && allScreen ? "dataZone allScreen" :
            rol !== 'ADMIN' && !allScreen ? 'dataZone' :
                rol === "ADMIN" && allScreen ? 'dataZoneAdmin allScreen' : 'dataZoneAdmin'}>
            {formVisible &&
                <div className='chatForm'>
                    <select
                        onChange={(e) => setChatDestination(e.target.value)}
                        className='chatSelect'>
                        <option value={0}>Consultoría</option>
                        <option value={1}>Soporte técnico</option>
                    </select>
                    <input
                        placeholder='Email'
                        onChange={(e) => setOriginMail(e.target.value)}
                        className='chatEmail' />
                    <input
                        placeholder='Nombre'
                        onChange={(e) => setOriginName(e.target.value)}
                        className='chatName' />
                    <textarea
                        placeholder='Mensaje'
                        onChange={(e) => {
                            setMessage(e.target.value);
                            e.target.style.height = 'auto'; // Restablece la altura para recalcular
                            e.target.style.height = `${e.target.scrollHeight}px`; // Ajusta a la altura del contenido
                        }}
                        className='chatMessage'
                        style={{ overflow: 'hidden', resize: 'none' }} // Evita barras de desplazamiento y deshabilita el redimensionado manual
                    />

                </div>}
            {enviarVisible && <button
                className='btnChatSend'
                onClick={() => fnSendMail()}>Enviar</button>}
            {confirmation &&
                <div className='messageContainer'>
                    <pre className='confirmationText'>{confirmation}</pre>
                    <button className='btnChatSend' onClick={() => setDataZoneView('dashboard')}>Aceptar</button>
                </div>

            }
        </div>
    )
}

export default Chat