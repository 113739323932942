import React from 'react'
import './AllScreen.css'
import flechaDobleDer from '../../Images/Icons/flechaDobleDer.png';
import flechaDobleIzq from '../../Images/Icons/flechaDobleIzq.png';

const AllScreen = ({setAllScreen, allScreen}) => {
  return (
    <div className='allScreenContainer'>
      <button onClick={()=>setAllScreen(!allScreen)} className={allScreen ? 'allScreenButton allScreenButtonClose' : 'allScreenButton'}>
        {allScreen ? <img src={flechaDobleDer} alt='desplegarMenu'/> : <img src={flechaDobleIzq} alt='desplegarMenu'/> }
      </button>
    </div>
  )
}

export default AllScreen
