import React, { useEffect, useState } from "react";
import "./DashboardView.css";
import flechaVerde from "../../../Images/Icons/dasboard/flechaVerde.png";
import flechaRoja from "../../../Images/Icons/dasboard/flechaRoja.png";
import TopLastGraphic from "../../../Components/Graphics/TopLastGraphic";
import { useAppContext } from "../../../Shared/Context/AppContext";
import AllProducts from "./AllProducts";
import useLanguages from "../../../Shared/languages/setLanguages";
import AllScreen from "../../../Components/AllScreen/AllScreen";
import RankingHotelsGraph from "../../../Components/Graphics/RankingHotelesGraph";

const formatNumber = (number) => {
  const numberStr = Number(number).toFixed(2);
  const [numberWithoutDec, decNumber] = numberStr.split(".");
  const formattedInt = numberWithoutDec.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (decNumber !== "00") {
    return `${formattedInt},${decNumber}`;
  } else {
    return `${formattedInt}`;
  }
};

const DashboardViewNew = () => {
  const { datosDashboardNew, rol, setDataZoneView, setSellPointDetail } = useAppContext();
  const graphArr = Array.from({ length: 100 }, (_, index) => index + 1);
  const [allProductsVisible, setAllProductsVisible] = useState(false);
  const [sellPointObjectives, setSellPointObjectives] = useState(null);
  const [graphToShow, setGraphToShow] = useState("top");
  const [allScreen, setAllScreen] = useState(false);

  const changeWord = useLanguages()

  const getObjective = (periodeObjective, sellPointName) => {
    if (periodeObjective) {
      const sellPointObjective = periodeObjective.find(
        (item) => item.sellPointName === sellPointName
      );

      return sellPointObjective.objective;
    }

  };
  useEffect(() => {
    if (datosDashboardNew && datosDashboardNew.pointOfSaleIncome) {
      const sellPoints = datosDashboardNew.pointOfSaleIncome;
      const monthObjective = datosDashboardNew.pointOfSaleMonthObjective;
      const yearObjectives = datosDashboardNew.pointOfSaleYearObjective;
      const periodicity = datosDashboardNew.periodicity;
      const sellPointObjectivesArray = [];
      for (const sellPoint of sellPoints) {
        const sellPointName = sellPoint.pointOfSaleName;
        const sellPointIncome = sellPoint.pointOfSaleIncome;

        const objective =
          periodicity !== "100"
            ? getObjective(monthObjective, sellPoint.pointOfSaleName)
            : getObjective(yearObjectives, sellPoint.pointOfSaleName);
        sellPointObjectivesArray.push({
          sellPointName: sellPointName,
          sellPointIncome: sellPointIncome,
          objective: objective,
          percent: ((sellPointIncome * 100) / objective).toFixed(),
        });
      }

      setSellPointObjectives(sellPointObjectivesArray);
    }
  }, [datosDashboardNew]);

  return (
    <div className={rol !== 'ADMIN' && allScreen ? "dataZone allScreen" :
      rol !== 'ADMIN' && !allScreen ? 'dataZone' :
        rol === "ADMIN" && allScreen ? 'dataZoneAdmin allScreen' : 'dataZoneAdmin'}>
      {/* <AllScreen setAllScreen={setAllScreen} allScreen={allScreen} /> */}
      {datosDashboardNew ? (
        <div className="dataZoneContainer">
          <div className="dataZoneRowGraph">
            {parseFloat(datosDashboardNew.totalHotelIncomeDif) !== undefined &&
              parseFloat(datosDashboardNew.totalHotelIncomeDifPercent) !==
              undefined && (
                <div className="dataZoneCard">
                  <div className="dataZoneCardTitle">
                    <p>{changeWord('ingresosTotales')}</p>
                  </div>
                  <div className="dataZoneCardItemGroupFirst">
                    <div className="dataZoneCardItem">
                      <p className="dataZoneCardItemD">
                        {formatNumber(datosDashboardNew.totalHotelIncome)}€
                      </p>
                    </div>
                    <div className="dataZoneCardItem">
                      {parseInt(datosDashboardNew.totalHotelIncome) >
                        parseInt(datosDashboardNew.preTotalHotelIncome) ? (
                        <div className="dataZoneCardItemEvolutionContainer">
                          <div className="dataZoneCardItemEvolutionItem">
                            <img
                              className="dataZoneCardItemEvolutionImage"
                              src={flechaVerde}
                              alt="arriba"
                            />
                          </div>
                          <div className="dataZoneCardItemText">
                            {parseInt(datosDashboardNew.totalHotelIncome) >
                              parseInt(datosDashboardNew.preTotalHotelIncome) ? (
                              <div>
                                <p className="dataZoneCardItemA green">
                                  {formatNumber(
                                    datosDashboardNew.totalHotelIncomeDif
                                  )}
                                  €
                                </p>

                                <p className="dataZoneCardItemB green">
                                  {formatNumber(
                                    datosDashboardNew.totalHotelIncomeDifPercent
                                  )}
                                  %
                                </p>
                              </div>
                            ) : (
                              <div>
                                <p className="dataZoneCardItemA red">
                                  {formatNumber(datosDashboardNew.totalIncomeDif)}€
                                </p>
                                <p className="dataZoneCardItemB red">
                                  {formatNumber(
                                    datosDashboardNew.totalHotelIncomeDifPercent
                                  )}
                                  %
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="dataZoneCardItemEvolutionContainer">
                          <div className="dataZoneCardItemEvolutionItem">
                            <img
                              className="dataZoneCardItemEvolutionImageBad"
                              src={flechaRoja}
                              alt="arriba"
                            />
                            
                          </div>
                          {(datosDashboardNew.totalHotelIncomeDif) &&
                            (
                              datosDashboardNew.totalHotelIncomeDifPercent
                            ) && (
                              <div className="dataZoneCardItemText">
                                {parseInt(datosDashboardNew.totalHotelIncome) >
                                  parseInt(datosDashboardNew.preTotalHotelIncome) ? (
                                  <div>
                                    <p className="dataZoneCardItemA green">
                                      {formatNumber(
                                        datosDashboardNew.totalHotelIncomeDif
                                      )}
                                      €
                                    </p>

                                    <p className="dataZoneCardItemB green">
                                      {formatNumber(
                                        datosDashboardNew.totalHotelIncomeDifPercent
                                      )}
                                      %
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <p className="dataZoneCardItemA red">
                                      {formatNumber(
                                        datosDashboardNew.totalHotelIncomeDif
                                      )}
                                      €
                                    </p>
                                    <p className="dataZoneCardItemB red">
                                      {formatNumber(
                                        datosDashboardNew.totalHotelIncomeDifPercent
                                      )}
                                      %
                                    </p>
                                  </div>
                                )}
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                  <p className="dataZoneCardItemC">
                    {changeWord('ventasPerdiodoAnterior')}:{" "}
                    {formatNumber(datosDashboardNew.preTotalHotelIncome)}€
                  </p>
                </div>
              )}

            {datosDashboardNew.totalHotelUnitsDif !== undefined &&
              datosDashboardNew.totalHotelUnitsDifPercent !== undefined && (
                <div className="dataZoneCard">
                  <div className="dataZoneCardTitle">
                    <p>{changeWord('unidadesVendidas')}</p>
                  </div>
                  <div className="dataZoneCardItemGroupFirst">
                    <div className="dataZoneCardItem">
                      <p className="dataZoneCardItemD">
                        {formatNumber(datosDashboardNew.totalHotelUnits)}Uds
                      </p>
                    </div>
                    <div className="dataZoneCardItem">
                      {datosDashboardNew.totalHotelUnits >
                        datosDashboardNew.preTotalHotelUnits ? (
                        <div className="dataZoneCardItemEvolutionContainer">
                          <div className="dataZoneCardItemEvolutionItem">
                            <img
                              className="dataZoneCardItemEvolutionImage"
                              src={flechaVerde}
                              alt="arriba"
                            />
                          </div>

                          <div className="dataZoneCardItemText">
                            {parseInt(datosDashboardNew.totalHotelUnits) >
                              parseInt(datosDashboardNew.preTotalHotelUnits) ? (
                              <div>
                                <p className="dataZoneCardItemA green">
                                  {formatNumber(
                                    datosDashboardNew.totalHotelUnitsDif
                                  )}
                                  Uds
                                </p>

                                <p className="dataZoneCardItemB green">
                                  {formatNumber(
                                    datosDashboardNew.totalHotelUnitsDifPercent
                                  )}
                                  %
                                </p>
                              </div>
                            ) : (
                              <div>
                                <p className="dataZoneCardItemA red">
                                  {datosDashboardNew.totalHotelUnitsDif}Uds
                                </p>
                                <p className="dataZoneCardItemB red">
                                  {formatNumber(
                                    datosDashboardNew.totalHotelUnitsDifPercent
                                  )}
                                  %
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="dataZoneCardItemEvolutionContainer">
                          <div className="dataZoneCardItemEvolutionItem">
                            <img
                              className="dataZoneCardItemEvolutionImageBad"
                              src={flechaRoja}
                              alt="arriba"
                            />
                          </div>
                          <div className="dataZoneCardItemText">
                            {parseInt(datosDashboardNew.hotalHotelUnits) >
                              parseInt(datosDashboardNew.preTotalHotelUnits) ? (
                              <div>
                                <p className="dataZoneCardItemA green">
                                  {datosDashboardNew.totalHotelUnitsDif}
                                </p>

                                <p className="dataZoneCardItemB green">
                                  {formatNumber(
                                    datosDashboardNew.totalHotelUnitsDifPercent
                                  )}
                                  %
                                </p>
                              </div>
                            ) : (
                              <div>
                                <p className="dataZoneCardItemA red">
                                  {datosDashboardNew.totalHotelUnitsDif}Uds
                                </p>
                                <p className="dataZoneCardItemB red">
                                  {formatNumber(
                                    datosDashboardNew.totalHotelUnitsDifPercent
                                  )}
                                  %
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <p className="dataZoneCardItemC">
                    {changeWord('unidadesVendidasPeriodoAnterior')}:{" "}
                    {formatNumber(datosDashboardNew.preTotalHotelUnits)}Uds
                  </p>
                </div>
              )}
            {(datosDashboardNew.hotelStancesDif) &&
              datosDashboardNew.hotelStancesDifPercent && (
                <div className="dataZoneCard">
                  <div className="dataZoneCardTitle">
                    <p>{changeWord('estancias')}</p>
                  </div>
                  <div className="dataZoneCardItemGroupFirst">
                    <div className="dataZoneCardItem">
                      <p className="dataZoneCardItemD">
                        {formatNumber(datosDashboardNew.hotelStances)}
                      </p>
                    </div>
                    <div className="dataZoneCardItem">
                      {datosDashboardNew.hotelStances >
                        datosDashboardNew.preHotelStances ? (
                        <div className="dataZoneCardItemEvolutionContainer">
                          <div className="dataZoneCardItemEvolutionItem">
                            <img
                              className="dataZoneCardItemEvolutionImage"
                              src={flechaVerde}
                              alt="arriba"
                            />
                          </div>
                          <div className="dataZoneCardItemText">
                            {parseInt(datosDashboardNew.hotelStances) >
                              parseInt(datosDashboardNew.preHotelStances) ? (
                              <div>
                                <p className="dataZoneCardItemA green">
                                  {formatNumber(datosDashboardNew.hotelStancesDif)}
                                </p>

                                <p className="dataZoneCardItemB green">
                                  {formatNumber(
                                    datosDashboardNew.hotelStancesDifPercent
                                  )}
                                  %
                                </p>
                              </div>
                            ) : (
                              <div>
                                <p className="dataZoneCardItemA red">
                                  {formatNumber(datosDashboardNew.hotelStancesDif)}
                                </p>
                                <p className="dataZoneCardItemB red">
                                  {formatNumber(
                                    datosDashboardNew.hotelStancesDifPercent
                                  )}
                                  %
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="dataZoneCardItemEvolutionContainer">
                          <div className="dataZoneCardItemEvolutionItem">
                            <img
                              className="dataZoneCardItemEvolutionImageBad"
                              src={flechaRoja}
                              alt="arriba"
                            />
                          </div>
                          <div className="dataZoneCardItemText">
                            {datosDashboardNew.hotelStances &&
                              parseInt(datosDashboardNew.hotelStances) >
                              parseInt(datosDashboardNew.preHotelStances) ? (
                              <div>
                                <p className="dataZoneCardItemA green">
                                  {formatNumber(datosDashboardNew.hotelStancesDif)}
                                </p>

                                <p className="dataZoneCardItemB green">
                                  {formatNumber(
                                    datosDashboardNew.hotelStancesDifPercent
                                  )}
                                  %
                                </p>
                              </div>
                            ) : (
                              <div>
                                <p className="dataZoneCardItemA red">
                                  {datosDashboardNew.hotelStancesDif &&
                                    datosDashboardNew.hotelStancesDif}

                                </p>
                                <p className="dataZoneCardItemB red">
                                  {formatNumber(
                                    datosDashboardNew.hotelStancesDifPercent
                                  )}
                                  %
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <p className="dataZoneCardItemC">
                    {changeWord('estanciasPeriodoAnterior')}:{" "}
                    {formatNumber(datosDashboardNew.preHotelStances)}
                  </p>
                </div>
              )}
          </div>
          {/* </div> */}
          <div className="dataZoneRowGraph">
            <div className="sellPointsIncomeVsObjective">
              <div className="kpis">
                {datosDashboardNew && (
                  <div className="dataZoneCard">
                    <div className="dataZoneCardTitle">
                      <p>{changeWord('ventasPorEmpleado')}</p>
                    </div>
                    <div className="dataZoneCardItemGroup">
                      <div className="dataZoneCardItem">
                        <p className="dataZoneCardItemD">
                          {datosDashboardNew.incomePerStaff &&
                            formatNumber(datosDashboardNew.incomePerStaff)}
                          €
                        </p>
                      </div>
                      <div className="dataZoneCardItem">
                        {parseFloat(datosDashboardNew.incomePerStaff) >
                          parseFloat(datosDashboardNew.preIncomePerStaff) ? (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImage"
                                src={flechaVerde}
                                alt="arriba"
                              />
                            </div>
                            <div className="dataZoneCardItemText">
                              {parseFloat(datosDashboardNew.incomePerStaff) >
                                parseFloat(datosDashboardNew.preIncomePerStaff) ? (
                                <div>
                                  <p className="dataZoneCardItemA green">
                                    {datosDashboardNew.incomePerStaffDif &&
                                      formatNumber(
                                        datosDashboardNew.incomePerStaffDif)
                                    }
                                    €
                                  </p>

                                  <p className="dataZoneCardItemB green">
                                    {datosDashboardNew.incomePerStaffDifPercent &&
                                      formatNumber(
                                        datosDashboardNew.incomePerStaffDifPercent)
                                    }
                                    %
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <p className="dataZoneCardItemA red">
                                    {datosDashboardNew.incomePerStaffDif &&
                                      formatNumber(
                                        datosDashboardNew.incomePerStaffDif
                                      )}
                                    €
                                  </p>
                                  <p className="dataZoneCardItemB red">
                                    {datosDashboardNew.incomePerStaffDifPercent &&
                                      formatNumber(
                                        datosDashboardNew.incomePerStaffDifPercent
                                      )}
                                    %
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImageBad"
                                src={flechaRoja}
                                alt="arriba"
                              />
                            </div>
                            <div className="dataZoneCardItemText">
                              {datosDashboardNew.incomePerStaff &&
                                parseInt(datosDashboardNew.incomePerStaff) >
                                parseInt(datosDashboardNew.preIncomePerStaff) ? (
                                <div>
                                  <p className="dataZoneCardItemA green">
                                    {datosDashboardNew.incomePerStaffDif &&
                                      formatNumber(
                                        datosDashboardNew.incomePerStaffDif
                                      )}
                                    €
                                  </p>

                                  <p className="dataZoneCardItemB green">
                                    {datosDashboardNew.incomePerStaffDifPercent &&
                                      formatNumber(
                                        datosDashboardNew.incomePerStaffDifPercent
                                      )}
                                    %
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <p className="dataZoneCardItemA red">
                                    {datosDashboardNew.incomePerStaffDif &&
                                      formatNumber(
                                        datosDashboardNew.incomePerStaffDif
                                      )}
                                    €
                                  </p>
                                  <p className="dataZoneCardItemB red">
                                    {datosDashboardNew.incomePerStaffDifPercent &&
                                      formatNumber(
                                        datosDashboardNew.incomePerStaffDifPercent
                                      )}
                                    %
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <p className="dataZoneCardItemC">
                      {changeWord('ventasEmpleadoPeriodoAnterior')}:{" "}
                      {datosDashboardNew.preIncomePerStaff &&
                        formatNumber(datosDashboardNew.preIncomePerStaff) || 0}
                      €
                    </p>
                  </div>
                )}

                {datosDashboardNew.ticketAverageDif !== undefined &&
                  datosDashboardNew.ticketAverageDifPercent !== undefined && (
                    <div className="dataZoneCard">
                      <div className="dataZoneCardTitle">
                        <p>{changeWord('ticketMedio')}</p>
                      </div>
                      <div className="dataZoneCardItemGroup">
                        <div className="dataZoneCardItem">
                          <p className="dataZoneCardItemD">
                            {datosDashboardNew.ticketAverage &&
                              formatNumber(datosDashboardNew.ticketAverage)}
                            €
                          </p>
                        </div>
                        <div className="dataZoneCardItem">
                          {parseFloat(datosDashboardNew.ticketAverage) >
                            parseFloat(datosDashboardNew.preTicketAverage) ? (
                            <div className="dataZoneCardItemEvolutionContainer">
                              <div className="dataZoneCardItemEvolutionItem">
                                <img
                                  className="dataZoneCardItemEvolutionImage"
                                  src={flechaVerde}
                                  alt="arriba"
                                />
                              </div>
                              <div className="dataZoneCardItemText">
                                {parseFloat(datosDashboardNew.ticketAverage) >
                                  parseFloat(datosDashboardNew.preTicketAverage) ? (
                                  <div>
                                    <p className="dataZoneCardItemA green">
                                      {datosDashboardNew.ticketAverageDif &&
                                        formatNumber(
                                          datosDashboardNew.ticketAverageDif
                                        )}
                                      €
                                    </p>

                                    <p className="dataZoneCardItemB green">
                                      {datosDashboardNew.ticketAverageDifPercent &&
                                        formatNumber(
                                          datosDashboardNew.ticketAverageDifPercent
                                        )}
                                      %
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <p className="dataZoneCardItemA red">
                                      {datosDashboardNew.ticketAverageDif &&
                                        formatNumber(
                                          datosDashboardNew.ticketAverageDif
                                        )}
                                      €
                                    </p>
                                    <p className="dataZoneCardItemB red">
                                      {datosDashboardNew.ticketAverageDifPercent &&
                                        formatNumber(
                                          datosDashboardNew.ticketAverageDifPercent
                                        )}
                                      %
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="dataZoneCardItemEvolutionContainer">
                              <div className="dataZoneCardItemEvolutionItem">
                                <img
                                  className="dataZoneCardItemEvolutionImageBad"
                                  src={flechaRoja}
                                  alt="arriba"
                                />
                              </div>
                              <div className="dataZoneCardItemText">
                                {datosDashboardNew.ticketAverage &&
                                  parseInt(datosDashboardNew.ticketAverage) >
                                  parseInt(datosDashboardNew.preTicketAverage) ? (
                                  <div>
                                    <p className="dataZoneCardItemA green">
                                      {datosDashboardNew.ticketAverageDif &&
                                        formatNumber(
                                          datosDashboardNew.ticketAverageDif
                                        )}
                                      €
                                    </p>

                                    <p className="dataZoneCardItemB green">
                                      {datosDashboardNew.ticketAverageDifPercent &&
                                        formatNumber(
                                          datosDashboardNew.ticketAverageDifPercent
                                        )}
                                      %
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <p className="dataZoneCardItemA red">
                                      {datosDashboardNew.ticketAverageDif &&
                                        formatNumber(
                                          datosDashboardNew.ticketAverageDif
                                        )}
                                      €
                                    </p>
                                    <p className="dataZoneCardItemB red">
                                      {datosDashboardNew.ticketAverageDifPercent &&
                                        formatNumber(
                                          datosDashboardNew.ticketAverageDifPercent
                                        )}
                                      %
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <p className="dataZoneCardItemC">
                        {changeWord('ticketMedioPeriodoAnterior')}:{" "}
                        {datosDashboardNew.preTicketAverage &&
                          formatNumber(datosDashboardNew.preTicketAverage)}
                        €
                      </p>
                    </div>
                  )}
              </div>
            </div>
            <div className="sellPointsIncomeVsObjective">
              <div className="kpis kpisMobile">
                {datosDashboardNew.incomePerStanceDif &&
                  datosDashboardNew.incomePerStanceDifPercent && (
                    <div className="dataZoneCard">
                      <div className="dataZoneCardTitle">
                        <p>{changeWord('ingresoPorEstancia')}</p>
                      </div>
                      <div className="dataZoneCardItemGroup">
                        <div className="dataZoneCardItem">
                          <p className="dataZoneCardItemD">
                            {datosDashboardNew.incomePerStance &&
                              formatNumber(datosDashboardNew.incomePerStance)}
                            €
                          </p>
                        </div>
                        <div className="dataZoneCardItem">
                          {parseFloat(datosDashboardNew.incomePerStance) >
                            parseFloat(datosDashboardNew.preIncomePerStance) ? (
                            <div className="dataZoneCardItemEvolutionContainer">
                              <div className="dataZoneCardItemEvolutionItem">
                                <img
                                  className="dataZoneCardItemEvolutionImage"
                                  src={flechaVerde}
                                  alt="arriba"
                                />
                              </div>
                              <div className="dataZoneCardItemText">
                                {parseFloat(datosDashboardNew.incomePerStance) >
                                  parseFloat(
                                    datosDashboardNew.preIncomePerStance
                                  ) ? (
                                  <div>
                                    <p className="dataZoneCardItemA green">
                                      {datosDashboardNew.incomePerStanceDif &&
                                        formatNumber(
                                          datosDashboardNew.incomePerStanceDif
                                        )}
                                      €
                                    </p>

                                    <p className="dataZoneCardItemB green">
                                      {datosDashboardNew.incomePerStanceDifPercent &&
                                        formatNumber(
                                          datosDashboardNew.incomePerStanceDifPercent
                                        )}
                                      %
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <p className="dataZoneCardItemA red">
                                      {datosDashboardNew.incomePerStanceDif &&
                                        formatNumber(
                                          datosDashboardNew.incomePerStanceDif
                                        )}
                                      €
                                    </p>
                                    <p className="dataZoneCardItemB red">
                                      {datosDashboardNew.incomePerStanceDifPercent &&
                                        formatNumber(
                                          datosDashboardNew.incomePerStanceDifPercent
                                        )}
                                      %
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="dataZoneCardItemEvolutionContainer">
                              <div className="dataZoneCardItemEvolutionItem">
                                <img
                                  className="dataZoneCardItemEvolutionImageBad"
                                  src={flechaRoja}
                                  alt="arriba"
                                />
                              </div>
                              <div className="dataZoneCardItemText">
                                {datosDashboardNew.incomePerStance &&
                                  parseInt(datosDashboardNew.incomePerStance) >
                                  parseInt(
                                    datosDashboardNew.preIncomePerStance
                                  ) ? (
                                  <div>
                                    <p className="dataZoneCardItemA green">
                                      {datosDashboardNew.incomePerStanceDif &&
                                        formatNumber(
                                          datosDashboardNew.incomePerStanceDif
                                        )}
                                      €
                                    </p>

                                    <p className="dataZoneCardItemB green">
                                      {datosDashboardNew.incomePerStanceDifPercent &&
                                        formatNumber(
                                          datosDashboardNew.incomePerStanceDifPercent
                                        )}
                                      %
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <p className="dataZoneCardItemA red">
                                      {datosDashboardNew.incomePerStanceDif &&
                                        formatNumber(
                                          datosDashboardNew.incomePerStanceDif
                                        )}
                                      €
                                    </p>
                                    <p className="dataZoneCardItemB red">
                                      {datosDashboardNew.incomePerStanceDifPercent &&
                                        formatNumber(
                                          datosDashboardNew.incomePerStanceDifPercent
                                        )}
                                      %
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <p className="dataZoneCardItemC">
                        {changeWord('ticketMedioPeriodoAnterior')}:{" "}
                        {datosDashboardNew.preIncomePerStance &&
                          formatNumber(datosDashboardNew.preIncomePerStance)}
                        €
                      </p>
                    </div>
                  )}
                {datosDashboardNew.averageItemDif &&
                  datosDashboardNew.averageItemDifPercent && (
                    <div className="dataZoneCard">
                      <div className="dataZoneCardTitle">
                        <p>{changeWord('precioPromedioVendido')}</p>
                      </div>
                      <div className="dataZoneCardItemGroup">
                        <div className="dataZoneCardItem">
                          <p className="dataZoneCardItemD">
                            {datosDashboardNew.averageSellItem &&
                              formatNumber(datosDashboardNew.averageSellItem)}
                            €
                          </p>
                        </div>
                        <div className="dataZoneCardItem">
                          {parseFloat(datosDashboardNew.averageSellItem) >
                            parseFloat(datosDashboardNew.averageItemSetted) ? (
                            <div className="dataZoneCardItemEvolutionContainer">
                              <div className="dataZoneCardItemEvolutionItem">
                                <img
                                  className="dataZoneCardItemEvolutionImage"
                                  src={flechaVerde}
                                  alt="arriba"
                                />
                              </div>
                              <div className="dataZoneCardItemText">
                                {parseInt(datosDashboardNew.averageSellItem) >
                                  parseInt(datosDashboardNew.averageItemSetted) ? (
                                  <div>
                                    <p className="dataZoneCardItemA green">
                                      {datosDashboardNew.averageItemDif &&
                                        formatNumber(
                                          datosDashboardNew.averageItemDif
                                        )}
                                      €
                                    </p>

                                    <p className="dataZoneCardItemB green">
                                      {datosDashboardNew.averageItemDifPercent &&
                                        formatNumber(
                                          datosDashboardNew.averageItemDifPercent
                                        )}
                                      %
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <p className="dataZoneCardItemA red">
                                      {datosDashboardNew.averageItemDif &&
                                        formatNumber(
                                          datosDashboardNew.averageItemDif
                                        )}
                                      €
                                    </p>
                                    <p className="dataZoneCardItemB red">
                                      {datosDashboardNew.averageItemDifPercent &&
                                        formatNumber(
                                          datosDashboardNew.averageItemDifPercent
                                        )}
                                      %
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="dataZoneCardItemEvolutionContainer">
                              <div className="dataZoneCardItemEvolutionItem">
                                <img
                                  className="dataZoneCardItemEvolutionImageBad"
                                  src={flechaRoja}
                                  alt="arriba"
                                />
                              </div>
                              <div className="dataZoneCardItemText">
                                {datosDashboardNew.averageSellItem &&
                                  parseInt(datosDashboardNew.averageSellItem) >
                                  parseInt(datosDashboardNew.averageItemSetted) ? (
                                  <div>
                                    <p className="dataZoneCardItemA green">
                                      {datosDashboardNew.averageItemDif &&
                                        formatNumber(
                                          datosDashboardNew.averageItemDif
                                        )}
                                      €
                                    </p>

                                    <p className="dataZoneCardItemB green">
                                      {datosDashboardNew.averageItemDifPercent &&
                                        formatNumber(
                                          datosDashboardNew.averageItemDifPercent
                                        )}
                                      %
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <p className="dataZoneCardItemA red">
                                      {datosDashboardNew.averageItemDif &&
                                        formatNumber(
                                          datosDashboardNew.averageItemDif
                                        )}
                                      €
                                    </p>
                                    <p className="dataZoneCardItemB red">
                                      {datosDashboardNew.averageItemDifPercent &&
                                        formatNumber(
                                          datosDashboardNew.averageItemDifPercent
                                        )}
                                      %
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <p className="dataZoneCardItemC">
                        {changeWord('precioPromedioEstablecido')}:{" "}
                        {datosDashboardNew.averageItemSetted &&
                          formatNumber(datosDashboardNew.averageItemSetted)}
                        €
                      </p>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="dataZoneRowGraph">
            <div className="sellPointsIncomeVsObjective">
              <div className="sellPointItem thisTitle">
                {datosDashboardNew.hotelId ? (
                  <div className="contributionTitle">
                    <p className='contributionTitleProd'>Punto de venta</p>
                    <p className='sellPointIncomeValue'>{changeWord('produccionPorPuntoDeVenta')}</p>
                    <p className='contributionTitleRat'>{changeWord('ratioDeContribucion')}</p>
                  </div>
                ) : (
                  <div className="contributionTitle">
                    <p className='contributionTitleProd'>Hotel</p>
                    <p className='sellPointIncomeValue'>{changeWord('produccionPorHotel')}</p>
                    <p className='contributionTitleRat'>{changeWord('ratioDeContribucionHot')}</p>

                  </div>
                )}
              </div>
              {sellPointObjectives &&
                sellPointObjectives.length > 0 &&
                sellPointObjectives.map((item, index) => (
                  <div key={index} className="contributionTitle">
                    <p className={datosDashboardNew.hotelId ? 'contributionTitleProd cursorPointer' : 'contributionTitleProd'}
                      onClick={() => {
                        if (datosDashboardNew.hotelId) {
                          setDataZoneView('sellPoints')
                          setSellPointDetail(item.sellPointName)
                        }

                      }}>{item.sellPointName}</p>
                    <p className='sellPointIncomeValue cursorPointer'>{formatNumber(item.sellPointIncome)}€</p>
                    <p className='contributionTitleRat cursorPointer'>{formatNumber((item.sellPointIncome) * 100 / datosDashboardNew.totalHotelIncome)}%</p>

                  </div>
                ))}
            </div>


            <div className="sellPointsIncomeVsObjective">
              {datosDashboardNew.hotelId ?
                <div className="dataZoneCardTitle">
                  <div className="rankingTitle">
                    <p>{changeWord('rankingDeProductos')}</p>
                  </div>
                </div>
                :
                <div className="dataZoneCardTitle">
                  <div className="rankingTitle">
                    <p>Ranking de hoteles</p>
                  </div>
                </div>
              }
              {datosDashboardNew.hotelId ?
                <div className="rankingProductosAndSelector">
                  {datosDashboardNew.topLastProductsHotel &&
                    datosDashboardNew.topLastProductsHotel.top &&
                    datosDashboardNew.topLastProductsHotel.last && (
                      <TopLastGraphic
                        data1={
                          graphToShow === "top"
                            ? datosDashboardNew.topLastProductsHotel.top
                            : graphToShow === "last"
                              ? datosDashboardNew.topLastProductsHotel.last
                              : datosDashboardNew.topLastProductsHotel.top
                        }
                        data2={datosDashboardNew.topLastProductsHotel.last}
                      />
                    )}
                  <div className="rankingProductosSelector">
                    <p
                      onClick={() => setGraphToShow("top")}
                      className={
                        graphToShow === "top"
                          ? "rankingProductosSelectorItem rankingProductosSelectedSelect"
                          : "rankingProductosSelectorItem"
                      }
                    >
                      top
                    </p>
                    <p
                      onClick={() => setGraphToShow("last")}
                      className={
                        graphToShow === "last"
                          ? "rankingProductosSelectorItem rankingProductosSelectedSelect"
                          : "rankingProductosSelectorItem"
                      }
                    >
                      last
                    </p>
                    <p
                      onClick={() => setAllProductsVisible(true)}
                      className="rankingProductosSelectorItem"
                    >
                      {changeWord('todos')}
                    </p>
                  </div>
                </div>
                :
                //null
                <RankingHotelsGraph data={datosDashboardNew.pointOfSaleIncome} />
              }
            </div>
          </div>
          {allProductsVisible && (
            <AllProducts
              setAllProductsVisible={setAllProductsVisible}
              data={datosDashboardNew.topLastProductsHotel.all}
              title={datosDashboardNew.hotelName}
            />
          )}
        </div>
      ) : (
        <div className="noData">
          <h1>SIN DATOS</h1>
        </div>
      )}
    </div>
  );
};

export default DashboardViewNew;
