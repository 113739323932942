import React, { useEffect, useState } from "react";
import "./TopNavbar.css";
import logo from "../../Images/logoHbd.svg";
import { useAppContext } from "../../Shared/Context/AppContext";
import dataGet from "../../Shared/API/dataGet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendario from "../../Images/Icons/calendario.png";
import useLanguages from "../../Shared/languages/setLanguages";
import { enGB } from 'date-fns/locale';
import api from "../../Shared/API/api";
import getAllHotelsData from "./GetAllHotelsData";
import SelectDates from "../../Pages/Views/General/SelectDates";

function getYesterdayDateString() {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  const formattedDate = today.toISOString().split("T")[0];
  return `${formattedDate},${formattedDate}`;
}

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const TopNavbar = ({
  setDataToShow,
  dataToShow,
  setDates,
  dates,
  hotelSelected,
  setHotelSelected,
}) => {
  const {
    groupData,
    setGroupData,
    hotelData,
    setHotelLogo,
    hotelLogo,
    selectedDepartment,
    setSelectedDepartment,
    setPeriodicity,
    periodicity,
    setDataZoneView,
    onlyOneHotel,
    allGroups,
    setDatosDashboardNew,
    datosDashboardNew,
    setLanguage,
    language,
    setloading,
    thisUser,
    setRol,
    objectivesView,
  } = useAppContext();

  const changeWord = useLanguages();

  useEffect(() => {
    if (onlyOneHotel) {
      setHotelSelected(onlyOneHotel._id)
    }
  }, [])

  useEffect(() => {
    const unicDate = dates.split(",");
    const dateParts = unicDate[0].split("-");
    const formatDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    let fechaResultadoFormat = "";
    if (unicDate) {
      const unicDateDate = new Date(unicDate[0]);
      unicDateDate.setDate(unicDateDate.getDate() - 6);
      const fechaResultado = unicDateDate.toISOString().split("T")[0];
      const fechaResultadoParts = fechaResultado.split("-");
      fechaResultadoFormat = `${fechaResultadoParts[2]}-${fechaResultadoParts[1]}-${fechaResultadoParts[0]}`;
    }

    const actualMonth = months[parseInt(unicDate[0].split("-")[1]) - 1];
    if (periodicity === "1") {
      setDataToShow(formatDate.split('-')[0]);
    } else if (periodicity === "5") {
      const actualInitMonth = months[parseInt(fechaResultadoFormat.split("-")[1]) - 1];
      setDataToShow(
        `${changeWord("del")} ${actualInitMonth} ${changeWord(
          "al"
        )} ${formatDate}`
      );
    } else if (periodicity === "10") {

      setDataToShow(`desde el 01 hasta el ${formatDate.split('-')[0]} de ${actualMonth} de ${unicDate[0].split("-")[0]}`);
    } else if (periodicity === "100") {
      const actualYear = unicDate[0].split("-")[0];
      setDataToShow(`desde el 01 de enero hasta el ${formatDate.split('-')[0]} de ${actualMonth} de ${unicDate[0].split("-")[0]}`);
    }
  }, [periodicity, dates]);

  const [changeDatesVisible, setChangeDatesVisible] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [hotelToShow, setHotelToShow] = useState("");
  const [selectValue, setSelectValue] = useState("inicio");
  const [groupSelectorVisible, setGroupSelectorVisible] = useState(false);
  const [groupNameList, setGroupNameList] = useState(null);
  const [customizedDatesVisible, setCustomizedDatesVisible] = useState(false);
  const [customDateInit, setCustomDateInit] = useState(null);
  const [customDateEnd, setCustomDateEnd] = useState(null);
  const [thisUserName, setThisUserName] = useState(null)

  useEffect(() => {
    if (allGroups) {
      const groupList = allGroups.map((item) => {
        return { id: item._id, groupName: item.name };
      });
      setGroupNameList(groupList);
      setGroupSelectorVisible(true);
    } else {
      setGroupSelectorVisible(false);
    }
  }, [allGroups]);

  useEffect(() => {
    if (thisUser) {
      const name = thisUser.name.split(',')[1];
      setThisUserName(name.trim())
    }
  }, [thisUser])

  // useEffect(() => {
  //   const date = new Date(startDate).toISOString().split("T")[0];
  //   setDates(`${date},${date}`);
  //   const dateParts = date.split("-");
  //   const formatDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
  //   if (periodicity === "1") {
  //     setDataToShow(`${formatDate}`);
  //     setChangeDatesVisible(false);
  //   }
  //   if (periodicity === "5") {
  //     // Convierte la cadena de texto (yyyy-mm-dd) a un objeto Date
  //     const originalDate = new Date(date);

  //     // Restar 6 días (6 días en milisegundos)
  //     originalDate.setDate(originalDate.getDate() - 6);

  //     // Devuelve la fecha formateada de nuevo como yyyy-mm-dd
  //     const yyyy = originalDate.getFullYear();
  //     const mm = String(originalDate.getMonth() + 1).padStart(2, "0"); // Mes empieza desde 0
  //     const dd = String(originalDate.getDate()).padStart(2, "0");
  //     const sixDaysAgo = `${dd}-${mm}-${yyyy}`;
  //     setDataToShow(`${sixDaysAgo} al ${formatDate}`);
  //     setChangeDatesVisible(false);
  //   }
  // }, [startDate]);

  useEffect(() => {
    if (datosDashboardNew) {
      const thisDataToShow = datosDashboardNew.datesToShow;
      const thisDataInitDay = thisDataToShow.min.split('-')[2];
      const thisDataInitMonth = thisDataToShow.min.split('-')[1];
      const thisDataInitYear = thisDataToShow.min.split('-')[0];

      const thisDataEndDay = thisDataToShow.max.split('-')[2];
      const thisDataEndMonth = thisDataToShow.max.split('-')[1];
      const thisDataEndYear = thisDataToShow.max.split('-')[0];
      if (thisDataToShow.min === thisDataToShow.max) {
        setDataToShow(`${thisDataInitDay} de ${months[parseInt(thisDataInitMonth) - 1]} de ${thisDataInitYear}`)
      } else {
        setDataToShow(`desde el ${thisDataInitDay} de ${months[parseInt(thisDataInitMonth) - 1]} de ${thisDataInitYear} al ${thisDataEndDay} de ${months[parseInt(thisDataEndMonth) - 1]} de ${thisDataEndYear}`)
      }

    }

  }, [datosDashboardNew])

  const fnSetDatosDashboard = () => {
    if (hotelSelected) {
      setloading(true); // Inicia el loading
      if (hotelSelected.toString() === "0") {
        setloading(false); // Finaliza el loading si es "0"
      } else if (hotelSelected.toString() === "1") {
        console.log('todos los hoteles');
        getAllHotelsData(groupData, dates, periodicity, setDatosDashboardNew, setHotelToShow, hotelData)
          .then(() => {
            setloading(false); // Finaliza el loading después de obtener los datos
          })
          .catch(() => {
            setloading(false); // Finaliza el loading en caso de error
          });
      } else {
        console.log('ejecuta get hotel');
        if (hotelSelected && periodicity) {
          api.get("/hotel/id/" + hotelSelected)
            .then((response) => {
              console.log('ejecuta get datos');
              return dataGet.getFBData(dates, hotelSelected, false, response.idHotelGroup, periodicity);
            })
            .then((getResponse) => {
              console.log('recibe el get');
              setHotelToShow(getResponse.datosDashboard.hotelName);
              setDatosDashboardNew(getResponse.datosDashboard);
              console.log('acaba');
              setCustomDateInit(null)
              setCustomDateEnd(null)
              setloading(false); // Finaliza el loading cuando todos los datos se han recibido
            })
            .catch(() => {
              setloading(false); // Finaliza el loading en caso de error
            });
        } else {
          setloading(false)
        }
      }
    }
  }
  useEffect(() => {
    if (periodicity !== '1000') {
      fnSetDatosDashboard()
    } else {
      if (customDateInit && customDateEnd) {
        const customDates = `${customDateInit},${customDateEnd}`
        setDates(customDates)
        fnSetDatosDashboard()

      }

    }

  }, [hotelSelected, periodicity, dates, customDateInit, customDateEnd]);

  useEffect(() => {
    if (periodicity === '1000') {
      setCustomizedDatesVisible(true);
    } else {
      setCustomizedDatesVisible(false);
    }
  }, [periodicity])

  useEffect(() => {
    const hotelId = hotelData.idHotelGroup?._id || hotelData.idHotelGroup;
    if (hotelId) {
      dataGet.getLogo(hotelId).then((logoResponse) => {
        setHotelLogo(logoResponse);
      });
    }
  }, [setDatosDashboardNew, setHotelLogo]);

  const fnChangeMyProfile = (value) => {
    if (value === "perfil") {
      setDataZoneView("SETTINGS");
      setSelectValue("inicio");
    }
    if (value === 'logOut') {
      localStorage.removeItem('userConnectedName');
      localStorage.removeItem('userConnectedPosition');
      setRol(null);
      setDatosDashboardNew(null)
    }
  };

  const fnSelectGroup = async (group) => {
    if (group && hotelData) {
      if (group !== "0") {
        const myGroupData = await dataGet.hotelGroupId(group);
        setGroupData(myGroupData);
      }
    }
  };
  return (
    <div className="topNavbar">
      <div className="hbdLogo">
        <img src={logo} alt="hbd logo" title="logo hbd" />
      </div>
      <div className="selectors_hotelName">
        <div className="selectorsTop">
          <div className="selectGroup_Hotel">
            {groupSelectorVisible && (
              <select
                onChange={(e) => fnSelectGroup(e.target.value)}
                className="departmentSelectorHotel"
              >
                <option value="0">{changeWord("grupo")}</option>
                {groupNameList &&
                  groupNameList.length > 0 &&
                  groupNameList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.groupName}
                    </option>
                  ))}
              </select>
            )}
            {!onlyOneHotel && (
              <div className="topNavbarSelectorGroup">
              <label className="topNavbarSelectorTitle">Hotel</label>
              <select
                className="departmentSelectorHotel"
                onChange={(e) => {
                  setHotelSelected(e.target.value);
                  setDates(getYesterdayDateString());
                  setChangeDatesVisible(false);
                }}
              >
                {groupData?.length > 0 &&
                  groupData.map((hotel, index) => (
                    <option key={index} value={hotel._id}>
                      {hotel.hotelName}
                    </option>
                  ))}
                {groupData && !onlyOneHotel && (
                  <option value={1}>{changeWord("todosLosHoteles")}</option>
                )}
              </select>
              </div>
            )}
          </div>
          <div className="topNavbarSelectorGroup">
            <label className="topNavbarSelectorTitle">Departamentos</label>
            <select
              value={selectedDepartment}
              onChange={(e) => {
                setSelectedDepartment(e.target.value);
                setDates(getYesterdayDateString());
                setChangeDatesVisible(false);
              }}
              className="departmentSelector"
            >
              {/* <option value="">{changeWord("departamentos")}</option> */}
              {hotelSelected && <option value="Front">Front desk</option>}
              {hotelSelected && <option value="FB">Food & Beverage</option>}
              {hotelSelected && <option value="SPA">SPA</option>}
            </select>
          </div>

          {!objectivesView &&
            <div>
              <div className="topNavbarSelectorGroup">
              <label className="topNavbarSelectorTitle">Periodo</label>
              <select
                className="departmentSelector"
                onChange={(e) => {
                  setPeriodicity(e.target.value);
                  setDates(getYesterdayDateString());
                  setChangeDatesVisible(false);
                }}
                value={periodicity}
              >
                {selectedDepartment && (
                  <option value={1}>{changeWord("diario")}</option>
                )}
                {selectedDepartment && (
                  <option value={5}>{changeWord("semanal")}</option>
                )}
                {selectedDepartment && (
                  <option value={10}>{changeWord("mensual")}</option>
                )}
                {selectedDepartment && (
                  <option value={100}>{changeWord("anual")}</option>
                )}
                {selectedDepartment && (
                  <option value={1000}>PERSONALIZADO</option>
                )}
              </select>
              </div>
              {customizedDatesVisible &&
                <div>
                  <SelectDates
                    hotelSelected={hotelSelected}
                    setCustomDateInit={setCustomDateInit}
                    setCustomDateEnd={setCustomDateEnd}
                    setCustomDateVisible={setCustomizedDatesVisible}
                  />
                </div>
              }
              {periodicity === '1000' && 
              <button 
              className="btnChangeCustomizedDates"
              onClick={()=>setCustomizedDatesVisible(true)}>
                Cambiar fechas</button>}
            </div>
          }

          {/* {!objectivesView &&
            <div>
              {(periodicity === "1" ||
                periodicity === "5" ||
                periodicity === "10" ||
                periodicity === "100") && (
                  <button
                    className="btnChangeDates"
                    title="modificar fechas"
                    onClick={() => {
                      setChangeDatesVisible(!changeDatesVisible);
                    }}
                  >
                    <img src={calendario} alt="calendario" />
                  </button>
                )}

              {changeDatesVisible
                && (
                  <div>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="yyyy-MM-dd" // Formato de la fecha
                      className="custom-datepicker" // Clase para estilos personalizados
                      popperPlacement="bottom" // Posición del calendario emergente
                      calendarClassName="custom-calendar" // Clase CSS para estilos del calendario
                      locale={enGB}
                    />
                  </div>
                )}

            </div>

          } */}

          <div className="languageSelector">
            <div className="laguageIcon" onClick={() => setLanguage("es")}>
              <p
                className={
                  language === "es"
                    ? "languageIconTextSelected"
                    : "languageIconText"
                }
              >
                ESP
              </p>
            </div>
            {/* <div className="laguageIcon" onClick={() => setLanguage("en")}>
              <p
                className={
                  language === "en"
                    ? "languageIconTextSelected"
                    : "languageIconText"
                }
              >
                ENG
              </p>
            </div> */}
          </div>
        </div>
        {thisUserName &&
          <div className="hotelTitle">
            <h2>
              Bienvenido/a, {thisUserName}
            </h2>
            <p className="dataToShowFormat">{dataToShow}</p>
          </div>}
      </div>

      <div className="logo_hotelName">
        <div className="hotelLogo">
          <img src={hotelLogo} alt="logo hotel" className="iconImg" />
        </div>
        {/* <p className="userLogged">{thisUser.name}</p> */}
        <div className="settingsIcon">
          <select
            onChange={(e) => fnChangeMyProfile(e.target.value)}
            className="selectPerfil"
            value={selectValue}
          >
            <option value="inicio">{changeWord("miPerfil")}</option>
            <option value="perfil">
              {changeWord("configuracionDePerfil")}
            </option>
            <option value="logOut">
              Cerrar sesión
            </option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default TopNavbar;
