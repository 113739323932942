import React, { useEffect, useState } from 'react';
import './DeleteObjective.css';

const DeleteObjective = ({
    setDeleteObjectiveVisible,
    onSubmit,
    deleteObjective,
}) => {
    
    console.log(deleteObjective)

    const fnClose = () => {
        // Llamamos a la función onSubmit para enviar los datos al padre
        onSubmit(deleteObjective.objectiveId);

        // Cerramos el popup
        setDeleteObjectiveVisible(false);
    };

    return (
        <div>
            <div className="popupOverlay">
                <div className="popupContainerDelete">
                    <p>Eliminarás los objetivos de</p>
                    <p>{deleteObjective.month} de {deleteObjective.year}</p>
                    <div className="selectDatesContainer">
                        {/* Botones */}
                        <button onClick={() => fnClose()}>Aceptar</button>
                        <button onClick={() => setDeleteObjectiveVisible(false)}>Cancelar</button>
                    </div>
             

                </div>
            </div>
        </div>
    );
};

export default DeleteObjective;
