import React, { useState } from 'react'
import './FdCrossellingProducts.css'
import AllScreen from '../../../Components/AllScreen/AllScreen'
import { useAppContext } from '../../../Shared/Context/AppContext';


const FdCrossellingProducts = () => {

  const [allScreen, setAllScreen] = useState(false);
  const { rol } = useAppContext();
  return (
    <div className={rol !== 'ADMIN' && allScreen ? "dataZone allScreen" : 
      rol !== 'ADMIN' && !allScreen ? 'dataZone' :
       rol === "ADMIN" && allScreen ? 'dataZoneAdmin allScreen' : 'dataZoneAdmin'}>
    <AllScreen setAllScreen={setAllScreen} allScreen={allScreen}/>
      <div className="crosselingProductsContainer">
        <div className="listZoneCrosselling">
        <p className="listZoneCategoryCrosselling listZoneTitle">Familia</p>
            <p className="listZoneCategoryCrosselling listZoneTitle">Categoria</p>
            <p className="listZoneCategoryCrosselling listZoneTitle">Unidades</p>
        </div>
      </div>
    </div>
  )
}

export default FdCrossellingProducts
