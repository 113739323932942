import React, { useState } from 'react'
import './Tutoriales.css'
import { useAppContext } from '../../../Shared/Context/AppContext';

const Tutoriales = () => {
    const { rol } = useAppContext();
    const [allScreen, setAllScreen] = useState(false);
    return (
        <div className={rol !== 'ADMIN' && allScreen ? "dataZone allScreen" :
            rol !== 'ADMIN' && !allScreen ? 'dataZone' :
                rol === "ADMIN" && allScreen ? 'dataZoneAdmin allScreen' : 'dataZoneAdmin'}>
            <div>
                <p>Tutoriales</p>
            </div>
        </div>
    )
}

export default Tutoriales