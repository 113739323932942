import React, { useEffect, useState } from 'react';
import './NewObjective.css';

const NewObjective = ({
    setNewObjectiveVisible,
    months,
    onSubmit,
    objectivesData
}) => {
    const [thisMonth, setThisMonth] = useState('0');
    const [thisYear, setThisYear] = useState(new Date().getFullYear());
    const [monthList, setMonthList] = useState(months.slice(0, 12));
    const [yearList, setYearList] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const [actualObjectives, setActualObjectives] = useState(null);
    const [confirmText, setConfirmText] = useState({text: null, ok: false})

    useEffect(() => {
        // Configuramos la lista de años actual y el próximo
        const currentYear = new Date().getFullYear();
        setYearList([currentYear, currentYear + 1]);
        const monthYearObjectives = objectivesData.map((item) => {
            return {
                month: months.indexOf(item.month),
                year: item.year
            }
        });
        setActualObjectives(monthYearObjectives)
    }, []);

    const fnClose = () => {
        // Llamamos a la función onSubmit para enviar los datos al padre
        onSubmit({ month: thisMonth, year: thisYear });

        // Cerramos el popup
        setNewObjectiveVisible(false);
    };
    const fnPreClose = () => {
        const objectiveToCreate = { month: thisMonth, year: thisYear }
        const exist = actualObjectives.some((item) =>
            parseInt(item.month) === parseInt(objectiveToCreate.month) &&
            parseInt(item.year) === parseInt(objectiveToCreate.year)
        )
        let text = '';
        let isOk = true;
        if(!exist){
            text = `Crear objetivos para ${months[thisMonth]} de ${thisYear}`
            isOk = true
        }else{
            text = `Ya existen objetivos para ${months[thisMonth]} de ${thisYear}`
            isOk = false
        }
        setConfirmText({text: text, ok: isOk})
        setConfirm(true)
    }

    return (
        <div>
            <div className="popupOverlay">
                <div className="popupContainer">
                    {!confirm ?
                        <div className="selectDatesContainer">
                            {/* Selección de Mes */}
                            <select onChange={(e) => setThisMonth(e.target.value)} value={thisMonth}>
                                {monthList.map((month, index) => (
                                    <option key={index} value={index}>
                                        {month}
                                    </option>
                                ))}
                            </select>

                            {/* Selección de Año */}
                            <select onChange={(e) => setThisYear(e.target.value)} value={thisYear}>
                                {yearList.map((year, index) => (
                                    <option key={index} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>

                            {/* Botones */}
                            <button onClick={fnPreClose}>Crear objetivos</button>
                            <button onClick={() => setNewObjectiveVisible(false)}>Cancelar</button>
                        </div>
                        :
                        <div className="selectDatesContainer">
                            <p>{confirmText.text}</p>
                            {confirmText.ok && <button onClick={fnClose}>Aceptar</button>}
                            <button onClick={() => setNewObjectiveVisible(false)}>Cancelar</button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default NewObjective;
