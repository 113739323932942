import React, { useState } from 'react'
import './FdRankingHabitaciones.css'
import FdRankingHabitacionesGraph from '../../../Components/Graphics/FdRankingHagitacionesGrap'
import { useAppContext } from '../../../Shared/Context/AppContext'
import AllScreen from '../../../Components/AllScreen/AllScreen'

const rankingHabitacionesTest = [
    {
        category: 'categoria1',
        units: 15,
        income: 150
    },
    {
        category: 'categoria2',
        units: 10,
        income: 130
    },
    {
        category: 'categoria3',
        units: 17,
        income: 170
    },
    {
        category: 'categoria4',
        units: 20,
        income: 300
    },
    {
        category: 'categoria5',
        units: 12,
        income: 100
    }
]
const FdRankingHabitaciones = () => {
    const [allScreen, setAllScreen] = useState(false);
    const {rol} = useAppContext();
  return (
    <div className={rol !== 'ADMIN' && allScreen ? "dataZone allScreen" : 
        rol !== 'ADMIN' && !allScreen ? 'dataZone' :
         rol === "ADMIN" && allScreen ? 'dataZoneAdmin allScreen' : 'dataZoneAdmin'}>
      <AllScreen setAllScreen={setAllScreen} allScreen={allScreen}/>
        <div className='fdRankingHabitacionesContainer'>
        <FdRankingHabitacionesGraph dataSet={rankingHabitacionesTest} />
        </div>
      
    </div>
  )
}

export default FdRankingHabitaciones
