import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const backGroundColorGraph = [
  'rgba(204, 0, 102, 1)',    // Color 1
  'rgba(34, 139, 34, 1)',    // Color 2
  'rgba(246, 94, 10, 1)',    // Color 3
  'rgba(10, 117, 198, 1)',   // Color 4
  'rgba(169, 169, 169, 1)',  // Color 5
  'rgba(255, 165, 0, 1)',    // Color 6
  'rgba(75, 0, 130, 1)',     // Color 7
  'rgba(220, 20, 60, 1)',    // Color 8
  'rgba(50, 205, 50, 1)',    // Color 9
  'rgba(255, 215, 0, 1)'     // Color 10
];
const backGroundColorGraphUnits = [
  'rgba(204, 0, 102, 0.5)',    // Color 1
  'rgba(34, 139, 34, 0.5)',    // Color 2
  'rgba(246, 94, 10, 0.5)',    // Color 3
  'rgba(10, 117, 198, 0.5)',   // Color 4
  'rgba(169, 169, 169, 0.5)',  // Color 5
  'rgba(255, 165, 0, 0.5)',    // Color 6
  'rgba(75, 0, 130, 0.5)',     // Color 7
  'rgba(220, 20, 60, 0.5)',    // Color 8
  'rgba(50, 205, 50, 0.5)',    // Color 9
  'rgba(255, 215, 0, 0.5)'     // Color 10
];

const FdRankingEmpleadosGraph = ({ dataSet, type, dashboard }) => {
  let filteredData;
  if (type === 'all') {
    filteredData = dataSet.map((item) => ({
      name: item.name,
      income: (item.upselling.income || 0) + (item.crosselling.income || 0),
      units: (item.upselling.units || 0) + (item.crosselling.units || 0),
    }));
  } else {
    filteredData = dataSet.map((item) => ({
      name: item.name,
      income: item[type]?.income || 0,
      units: item[type]?.units || 0,
    }));
  }

  filteredData.sort((a, b) => b.income - a.income);

  const labels = filteredData.map(item => item.name);

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Ingresos',
        data: filteredData.map(item => item.income),
        backgroundColor: backGroundColorGraph,
        borderColor: backGroundColorGraph,
        borderWidth: 1,
        yAxisID: 'y-axis-income',  // Eje para income
      },
      !dashboard && {
        label: 'Unidades',
        data: filteredData.map(item => item.units),
        backgroundColor: backGroundColorGraphUnits,
        borderColor: backGroundColorGraphUnits,
        borderWidth: 1,
        yAxisID: 'y-axis-units',  // Eje para units
      },
    ].filter(Boolean),  // Elimina los valores falsy (como `false`) del array
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 16,
            weight: 'bold'
          }
        }
      },
      'y-axis-income': {
        type: 'linear',
        position: 'left',
        ticks: {
          callback: function (value) {
            return value + '€'; // Formatear los valores de income
          },
        },
        title: {
          display: true,
          text: 'Ingresos(€)',
        },
      },
      ...(!dashboard ? {
        'y-axis-units': {
          type: 'linear',
          position: 'right',
          ticks: {
            callback: function (value) {
              return value + ' uds'; // Formatear los valores de units
            },
          },
          title: {
            display: true,
            text: 'Unidades',
          },
        },
      } : {}),
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default FdRankingEmpleadosGraph;
