import React, { useEffect, useState } from "react";
import "./Reports.css";
import { useAppContext } from "../../../Shared/Context/AppContext";
import dataGet from "../../../Shared/API/dataGet";
import { PDFDocument } from "pdf-lib";
const Reports = ({ hotelSelected }) => {
  const { selectedDepartment, hotelData } = useAppContext();
  const [reportTypes, setReportTypes] = useState([]);
  const [reportSelected, setReportSelected] = useState(null);
  const [reportOptions, setReportOptions] = useState(null);
  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);
  const [yearMonth, setYearMonth] = useState(null);
  const [dayWeek, setDayWeek] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [lastDayOfMonth, setLastDayOfMonth] = useState(null);
  const [validHotelSelected, setValidHotelSelected] = useState(false);

  const monthList = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  useEffect(() => {
    if (hotelSelected && hotelSelected !== "1") {
      setValidHotelSelected(true);
    } else {
      setValidHotelSelected(false);
    }
  }, [hotelSelected]);
  useEffect(() => {
    let reportList;
    if (selectedDepartment === "FB") {
      reportList = [
        {
          name: "Jefe departamento",
          value: "fbday",
        },
        {
          name: "Director/a",
          value: "fbweek",
        },
        {
          name: "Corporativo",
          value: "fbmonth",
        },
        // {
        //   name: "Incentivos",
        //   value: "fbIncentives",
        // },
      ];
    } else if (selectedDepartment === "FD") {
      reportList = [
        {
          name: "Jefe recepción",
          value: "fdday",
        },
        {
          name: "Director/a",
          value: "fdweek",
        },
        // {
        //   name: "Corporativo",
        //   value: "fdmonth",
        // },
        // {
        //   name: "Incentivos",
        //   value: "fdIncentives",
        // },
      ];
    }
    setReportTypes(reportList);
  }, [selectedDepartment]);
  useEffect(() => {
    if (reportSelected && reportSelected !== "Selecciona informe") {
      if (reportSelected.includes("day")) {
        setReportOptions("day");
      }
      if (reportSelected.includes("week")) {
        setReportOptions("week");
      }
      if (reportSelected.includes("month")) {
        setReportOptions("month");
      }
      if (reportSelected.includes("Incentives")) {
        setReportOptions("incentives");
      }
    }
  }, [reportSelected]);

  const getSixDaysBefore = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() - 6);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (dayWeek) {
      const initWeek = getSixDaysBefore(dayWeek);
      dataGet
        .getFBweekReport(
          `${dayWeek}, ${dayWeek}`,
          hotelData._id,
          false,
          hotelData.idHotelGroup._id
        )
        .then(async (response) => {
          const pdfData = new Uint8Array(response.data);
          const pdfDoc = await PDFDocument.load(pdfData);
          const pdfBytes = await pdfDoc.save();
          const blob = new Blob([pdfBytes], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          setPdfUrl(url);
        })
        .catch((error) => {
          console.error("Error al obtener los datos:", error);
        });
    }
  }, [dayWeek]);

  useEffect(() => {
    if (day) {
      dataGet
        .getFBdailyReport(
          `${day}, ${day}`,
          hotelData._id,
          false,
          hotelData.idHotelGroup._id
        )
        .then(async (response) => {
          const pdfData = new Uint8Array(response.data);
          const pdfDoc = await PDFDocument.load(pdfData);
          const pdfBytes = await pdfDoc.save();
          const blob = new Blob([pdfBytes], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          setPdfUrl(url);
        })
        .catch((error) => {
          console.error("Error al obtener los datos:", error);
        });
    }
  }, [day]);

  useEffect(() => {
    if (lastDayOfMonth) {
      const dateParts = lastDayOfMonth.split("-");
      const initDate = `${dateParts[0]}-${dateParts[1]}-01`;
      dataGet
        .getFBmonthReport(
          `${initDate}, ${lastDayOfMonth}`,
          hotelData._id,
          false,
          hotelData.idHotelGroup._id
        )
        .then(async (response) => {
          const pdfData = new Uint8Array(response.data);
          const pdfDoc = await PDFDocument.load(pdfData);
          const pdfBytes = await pdfDoc.save();
          const blob = new Blob([pdfBytes], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          setPdfUrl(url);
        })
        .catch((error) => {
          console.error("Error al obtener los datos:", error);
        });
    }
  }, [lastDayOfMonth]);

  useEffect(() => {
    if (month && yearMonth) {
      const monthNum = monthList.indexOf(month) + 1;
      const getLastDayOfMonth = (year, month) => {
        const date = new Date(year, month, 0);
        const formattedDate = `${date.getFullYear()}-${String(
          date.getMonth() + 1
        ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
        return formattedDate;
      };

      const lastDay = getLastDayOfMonth(yearMonth, monthNum);
      setLastDayOfMonth(lastDay);
    }
  }, [month, yearMonth, monthList]);

  const yearList = ["2023", "2024", "2025", "2026"];
  return (
    <div className="dataZone">
      <div className="reportContainer">
        <div className="reportSelectContainer">
          <div>
            <select
              onChange={(e) => {
                setReportSelected(e.target.value);
              }}
              className="selectReport"
            >
              <option>Selecciona informe</option>
              {reportTypes &&
                reportTypes.length > 0 &&
                reportTypes.map((item, index) => (
                  <option value={item.value} key={index}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <div>
            {reportOptions && reportOptions === "day" && (
              <input
                type="date"
                onChange={(e) => {
                  const selectedDate = new Date(e.target.value);
                  selectedDate.setDate(selectedDate.getDate() + 1);
                  const adjustedDate = selectedDate.toISOString().split("T")[0];
                  setDay(adjustedDate);
                }}
                className="selectDate"
              />
            )}
          </div>
          <div>
            {reportOptions && reportOptions === "month" && (
              <div className="reportSelectContainer">
                <select
                  onChange={(e) => setMonth(e.target.value)}
                  className="selectDate"
                >
                  <option>Mes</option>
                  {monthList.map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
                <select
                  onChange={(e) => setYearMonth(e.target.value)}
                  className="selectDate"
                >
                  <option>Año</option>
                  {yearList.map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
              </div>
            )}
          </div>

          <div>
            {reportOptions && reportOptions === "week" && (
              <input
                type="date"
                onChange={(e) => setDayWeek(e.target.value)}
                className="selectDate"
              />
            )}
          </div>
        </div>
      </div>
      <div className="pdfContainer">
        {pdfUrl && validHotelSelected ? (
          <iframe src={pdfUrl} className="visorPdf"></iframe>
        ) : (
          <div className="reportError">
            {pdfUrl && <p>No hay informes para mostrar</p>}
            {!validHotelSelected && (
              <p>Selecciona un solo hotel de la cadena</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Reports;
