import React, { useEffect, useState } from 'react';
import './SelectDates.css';
import dataGet from '../../../Shared/API/dataGet';

const SelectDates = ({ setCustomDateInit, setCustomDateEnd, setCustomDateVisible, hotelSelected }) => {
    const [thisDateInit, setThisDateInit] = useState(null);
    const [thisDateEnd, setThisDateEnd] = useState(null);
    const [maxMinDate, setMaxMinDate] = useState({ min: '', max: '' });

    useEffect(() => {
        if (hotelSelected) {
            dataGet.getCalendarDates(hotelSelected).then((datesResponse) => {
                console.log(datesResponse)
                setMaxMinDate(datesResponse);
            });
        }
    }, [hotelSelected]); // Asegúrate de agregar una dependencia para evitar bucles infinitos

    const fnClose = () => {
        setCustomDateVisible(false);
        setCustomDateInit(thisDateInit);
        setCustomDateEnd(thisDateEnd);
    };
    return (
        <div>
            <div className="popupOverlay">
                <div className="popupContainer">
                    <div className="selectDatesContainer">
                        <input
                            type="date"
                            onChange={(e) => setThisDateInit(e.target.value)}
                            min={maxMinDate.minDate} // Establecer el mínimo
                            max={maxMinDate.maxDate} // Establecer el máximo
                        />
                        <input
                            type="date"
                            onChange={(e) => setThisDateEnd(e.target.value)}
                            min={maxMinDate.minDate} // Establecer el mínimo
                            max={maxMinDate.maxDate} // Establecer el máximo
                        />
                        <button onClick={fnClose}>Seleccionar</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectDates;
