import React, { useState } from "react";
import "./FdUpsellingProducts.css";
import AllScreen from "../../../Components/AllScreen/AllScreen";
import { useAppContext } from "../../../Shared/Context/AppContext";

const FdUpsellingProducts = () => {
  const [allScreen, setAllScreen] = useState(false);
  const {rol} = useAppContext();
  return (
    <div className={rol !== 'ADMIN' && allScreen ? "dataZone allScreen" : 
      rol !== 'ADMIN' && !allScreen ? 'dataZone' :
       rol === "ADMIN" && allScreen ? 'dataZoneAdmin allScreen' : 'dataZoneAdmin'}>
    <AllScreen setAllScreen={setAllScreen} allScreen={allScreen}/>
      <div className="upsellingProductsContainer">
        <div className="listZone">
            <p className="listZoneCategory listZoneTitle">Categoria</p>
            <p className="listZoneUnits listZoneTitle">Unidades</p>
        </div>
      </div>
    </div>
  );
};

export default FdUpsellingProducts;
